export enum AB_TESTS_KEYS {
  PWA_COMMUNICATION_V5 = 'pwa-communication-v5',
  WORDS_EXCEED_MODAL = 'words-exceed-modal',
  ES_LOCALIZATION = 'es_localization',
  TOOLS_TUTORIAL = 'tools_tutorial',
  ANNUAL_UPGRADE_IMPROVEMENT = 'annual-upgrade-improvement',
  WORDS_UPSELL_OPTION = 'words_upsell_option',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      [key: string]: string;
    };
    defaultVariant: string;
    isManualStart?: boolean;
  }
> = {
  [AB_TESTS_KEYS.WORDS_EXCEED_MODAL]: {
    key: AB_TESTS_KEYS.WORDS_EXCEED_MODAL,
    variants: {
      control: 'control',
      variant_1: 'test',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.PWA_COMMUNICATION_V5]: {
    key: AB_TESTS_KEYS.PWA_COMMUNICATION_V5,
    variants: {
      control: '4.1',
      variant_1: '5.0',
    },
    defaultVariant: '4.1',
  },
  [AB_TESTS_KEYS.ES_LOCALIZATION]: {
    key: AB_TESTS_KEYS.ES_LOCALIZATION,
    variants: {
      control: 'en',
      variant_1: 'es',
    },
    defaultVariant: 'en',
    isManualStart: true,
  },
  [AB_TESTS_KEYS.ANNUAL_UPGRADE_IMPROVEMENT]: {
    key: AB_TESTS_KEYS.ANNUAL_UPGRADE_IMPROVEMENT,
    variants: {
      control: 'control',
      variant_1: 'test_single_screen',
      variant_2: 'test_multi_screen',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.WORDS_UPSELL_OPTION]: {
    key: AB_TESTS_KEYS.WORDS_UPSELL_OPTION,
    variants: {
      control: 'control',
      variant_1: 'upsell',
      variant_2: 'upsell_words',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.TOOLS_TUTORIAL]: {
    key: AB_TESTS_KEYS.TOOLS_TUTORIAL,
    variants: {
      control: 'control',
      variant_1: 'tested',
    },
    defaultVariant: 'control',
  },
};
