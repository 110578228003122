import { ArticleExecutionType, OutlineItemType } from '~/modules/tools/types';

// TODO! Localization. Decide how to use enums
export enum SCREEN {
  TOPIC = 'Set Topic',
  OUTlINE = 'Confirm Outline',
  ARTICLE = 'Read Article',
}

export enum REQUEST {
  GENERATE = 'generate',
  REGENERATE = 'regenerate',
}

export type ArticleOutlineType = {
  words: number;
  topic: string;
  items: OutlineItemType[];
};

export type ArticleType = {
  topic: string;
  outline: ArticleOutlineType | null;
  content: ArticleExecutionType | null;
};
