import { TFunction } from 'i18next';

export const ANNUAL_PLAN_OLD_PRICE = 600; // $
export const ANNUAL_PLAN_DISCOUNT = 500; // $
export const OLD_MONTHLY_PRICE = 29.99; // $
export const OLDER_MONTHLY_PRICE = 49.99; // $

export const getDescriptionBenefits = (data: {
  t: TFunction;
  isTrial: boolean;
}): string[] => {
  const { t, isTrial } = data;

  return [
    t('modal:upgrade_sub.benefits[0]', 'Unlimited Words For All Tools'),
    t(
      'modal:upgrade_sub.benefits[1]',
      'Early Access To New AI Models And Features',
    ),
    t('modal:upgrade_sub.benefits[2]', 'Unlimited Image Generations'),
    ...(isTrial
      ? [t('modal:upgrade_sub.benefits[3]', 'Increased input limits')]
      : []),
  ];
};
