import React, { FC, useMemo } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import { ModalContextProvider } from '~/contexts/modal/ModalContext';
import { PopoverContextProvider } from '~/contexts/popover/PopoverContext';
import { SnackBarContextProvider } from '~/features/snackbar/SnackbarContext';
import i18n from '~/i18n/config';
import { CheckAuthWrapper } from '~/middlewares/CheckAuthWrapper';
import { NewToolsIntroWrapper } from '~/middlewares/NewToolsIntroWrapper';
import ProfileStatusWrapper from '~/middlewares/ProfileStatusWrapper';
import { SubRebillWrapper } from '~/middlewares/SubRebillWrapper';
import { HighlightContextProvider } from '~/modules/highlight/HighlightContext';
import Router from '~/Router';
import GrowthBookProvider from '~/services/abTests/Provider';
import { store } from '~/store';
import createCustomTheme from '~/theme';

const themeMode = 'light';
const App: FC = () => {
  const theme = useMemo(() => createCustomTheme(themeMode), [themeMode]);

  return (
    <I18nextProvider i18n={i18n.instance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <BrowserRouter>
            <SnackBarContextProvider>
              <ModalContextProvider>
                <PopoverContextProvider>
                  <HighlightContextProvider>
                    <CheckAuthWrapper>
                      <GrowthBookProvider>
                        <ProfileStatusWrapper>
                          <SubRebillWrapper>
                            <NewToolsIntroWrapper>
                              <React.Suspense
                                fallback={<FullscreenPreloader />}
                              >
                                <Router />
                              </React.Suspense>
                            </NewToolsIntroWrapper>
                          </SubRebillWrapper>
                        </ProfileStatusWrapper>
                      </GrowthBookProvider>
                    </CheckAuthWrapper>
                  </HighlightContextProvider>
                </PopoverContextProvider>
              </ModalContextProvider>
            </SnackBarContextProvider>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
