import React, { FC } from 'react';

import { MenuItem, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PERSONALITY } from '~/modules/chat/types';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

export const getPersonalityConf = (
  t: TFunction,
): Record<
  PERSONALITY,
  {
    text: string;
    value: PERSONALITY;
    logo: string;
  }
> => ({
  [PERSONALITY.GENERAL]: {
    text: t('general:general', 'General'),
    value: PERSONALITY.GENERAL,
    logo: '',
  },
  [PERSONALITY.CAREER_COUNSELOR]: {
    text: t('general:career_counselor', 'Career Counselor'),
    value: PERSONALITY.CAREER_COUNSELOR,
    logo: '🎯',
  },
  [PERSONALITY.MARKETING_DIRECTOR]: {
    text: t('general:marketing_director', 'Marketing Director'),
    value: PERSONALITY.MARKETING_DIRECTOR,
    logo: '📈',
  },
  [PERSONALITY.MOTIVATIONAL_COACH]: {
    text: t('general:motivational_coach', 'Motivational Coach'),
    value: PERSONALITY.MOTIVATIONAL_COACH,
    logo: '💪',
  },
  [PERSONALITY.BUSINESS_ANALYST]: {
    text: t('general:business_analyst', 'Business Analyst'),
    value: PERSONALITY.BUSINESS_ANALYST,
    logo: '🔍',
  },
  [PERSONALITY.SALES_PROFESSIONAL]: {
    text: t('general:sales_professional', 'Sales Professional'),
    value: PERSONALITY.SALES_PROFESSIONAL,
    logo: '💼',
  },
  [PERSONALITY.COPYWRITING_MASTER]: {
    text: t('general:copywriting_master', 'Copywriting Master'),
    value: PERSONALITY.COPYWRITING_MASTER,
    logo: '✍',
  },
});

type Props = {
  personality: PERSONALITY;
  setPersonality: (type: PERSONALITY) => void;
  isDisabled?: boolean;
  className?: string;
};

const StyledTextField = styled(TextField)(() => ({
  '& .MuiSelect-select': {
    padding: '8px 36px 8px 5px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  '& .MuiSvgIcon-root': {
    color: colors.black,
  },
  '& input': {
    border: '0',
  },
  '& fieldset': {
    border: 'none',
  },
  '& .Mui-disabled': {
    '& .MuiSvgIcon-root': {
      opacity: '0.4',
    },
  },
}));

const Personality: FC<Props> = ({
  personality,
  setPersonality,
  isDisabled,
  className,
}) => {
  const { t } = useTranslation('chat');

  const personalityConf = getPersonalityConf(t);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Typography
        component="p"
        variant={Typographies.LABEL_LARGE}
        className={styles.description}
      >
        {t('personality.label', 'AI Assistant:')}
      </Typography>

      <div className={styles.select_wrapper}>
        <Typography component="p" variant={Typographies.LABEL_LARGE}>
          {personalityConf[personality].logo}
        </Typography>

        <StyledTextField
          select
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  marginLeft: 0,
                  borderRadius: '4px',
                  backgroundColor: colors.neutral_80,
                },
                sx: {
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: colors.primary_40,
                    'border-radius': '4px',
                  },
                },
              },
            },
          }}
          disabled={isDisabled}
          value={personality}
          onChange={(e): void => {
            setPersonality(e.target.value as PERSONALITY);
          }}
        >
          {Object.values(personalityConf).map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </StyledTextField>
      </div>
    </div>
  );
};

export default Personality;
