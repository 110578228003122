import { TFunction } from 'i18next';

import { getChatHighlightConfig } from '~/modules/chat/constants';
import { HighLightPlacementType } from '~/modules/highlight/components/HighlightBlock/Highlight';
import { getImageGeneratorHighlightConfig } from '~/modules/image-generator/constants';
import { getToolsCommonHighlightConfig } from '~/modules/tools/constants';
import { getAAGHighlightConfig } from '~/modules/tools/views/AdvancedArticleGenerator/constants';
import { getAiResearcherHighlightConfig } from '~/modules/tools/views/AiResearcher/constants';
import { getFactCheckerHighlightConfig } from '~/modules/tools/views/FactChecker/constants';
import { getAiDetectorHighlightConfig } from '~/modules/tools/views/variant2/AiDetectorV2/constants';
import { getAiHumanizerHighlightConfig } from '~/modules/tools/views/variant2/AiHumanizerV2/constants';
import { getParaphraserHighlightConfig } from '~/modules/tools/views/variant2/ParaphraseTextV3/constants';
import { getPlagiarismHighlightConfig } from '~/modules/tools/views/variant2/PlagiarismCheckerV2/constants';
import analytics from '~/services/analytics';

export enum HIGHLIGHT {
  SCRAPPER = 'scrapper',
  CHAT_PERSONALITY = 'chat_personality',
  GOOGLE_SEARCH = 'google_search',
  PROMPT_IMPROVER = 'prompt_improver',
  PROMPT_IMPROVER_DISLIKE = 'prompt_improver_dislike',
  PROMPT_IMPROVER_EFFECTIVE_USAGE = 'prompt_improver_effective_usage',
  TOOLS_OUTPUT_COUNTER = 'tools_output_counter',
  CHAT_ONBOARDING_STEP_1 = 'chat_onboarding_step_1',
  CHAT_ONBOARDING_STEP_2 = 'chat_onboarding_step_2',
  CHAT_ONBOARDING_STEP_3 = 'chat_onboarding_step_3',
  CHAT_ONBOARDING_STEP_4 = 'chat_onboarding_step_4',
  AAG_ONBOARDING_STEP_1 = 'aag_onboarding_step_1',
  AAG_ONBOARDING_STEP_2 = 'aag_onboarding_step_2',
  AAG_ONBOARDING_STEP_3 = 'aag_onboarding_step_3',
  IMAGE_GENERATOR_IMPROVE_PROMPT = 'image_generator_improve_prompt',
  IMAGE_GENERATOR_IMAGE_SIZE = 'image_generator_image_size',
  IMAGE_GENERATOR_QUALITY = 'image_generator_quality',
  DETECTORS_MONTHLY_LIMIT = 'detector_monthly_limit',
  AI_HUMANIZER_GOAL_SOUND_HUMAN = 'ai_humanizer_goal_sound_human',
  AI_HUMANIZER_GOAL_AUTO = 'ai_humanizer_goal_auto',
  AI_HUMANIZER_GOAL_BYPASS_DETECTOR = 'ai_humanizer_bypass_detector',
  AI_HUMANIZER_DETECT_AI = 'ai_humanizer_detect_ai',
  AI_HUMANIZER_AI_EDIT = 'ai_humanizer_ai_edit',
  AI_DETECTOR_TEXT_INPUT = 'ai_detector_text_input',
  AI_DETECTOR_NAVIGATOR = 'ai_detector_navigator',
  AI_DETECTOR_HUMANIZE_BUTTON = 'ai_detector_humanize_button',
  AI_DETECTOR_FACT_CHECK_BUTTON = 'ai_detector_fact_check_button',
  AI_DETECTOR_DETAILED_REPORT_BUTTON = 'ai_detector_detailed_report_button',
  AI_DETECTOR_TIPS_BUTTON = 'ai_detector_tips_button',
  AI_RESEARCHER_TOPIC = 'ai_researcher_topic',
  AI_RESEARCHER_TONE = 'ai_researcher_tone',
  AI_RESEARCHER_TYPE = 'ai_researcher_type',
  AI_RESEARCHER_SOURCES = 'ai_researcher_sources',
  AI_RESEARCHER_CITATIONS = 'ai_researcher_citations',
  AI_RESEARCHER_REPORT_FILE = 'ai_researcher_report_file',
  PLAGIARISM_SIMPLIFIED_SCAN = 'plagiarism_simplified_scan',
  PLAGIARISM_REMOVE_PLAGIARISM_BUTTON = 'plagiarism_remove_plagiarism_button',
  PLAGIARISM_DETAILED_REPORT_BUTTON = 'plagiarism_detailed_report_button',
  PARAPHRASER_LEVEL_MIN = 'paraphraser_level_min',
  PARAPHRASER_LEVEL_RECOMMENDED = 'paraphraser_level_recommended',
  PARAPHRASER_LEVEL_MAX = 'paraphraser_level_max',
  PARAPHRASER_TONE = 'paraphraser_tone',
  PARAPHRASER_VARIANTS = 'paraphraser_variants',
  PARAPHRASER_CHECK_PLAGIARISM_BUTTON = 'paraphraser_check_plagiarism_button',
  PARAPHRASER_AI_EDIT_BUTTON = 'paraphraser_ai_edit_button',
  FACT_CHECKER_TEXT_HIGHLIGHTS = 'fact_checker_text_highlights',
  FACT_CHECKER_ARROWS = 'fact_checker_arrows',
  FACT_CHECKER_RESPONSE = 'fact_checker_response',
  FACT_CHECKER_FIX_BUTTON = 'fact_checker_fix_button',
  FACT_CHECKER_DISMISS_BUTTON = 'fact_checker_dismiss_button',
}

export type HighLightConfigType = {
  title: string;
  text: string;
  buttonText?: string;
  subTitle?: string;
  placement?: HighLightPlacementType;
  onShowCallBack?: () => void;
  onSubmitCallback?: (
    updateUserExtraFields: (data: Record<string, any>) => void,
  ) => void;
};

export const getHighlightConfig = (
  t: TFunction,
): Record<HIGHLIGHT, HighLightConfigType> => ({
  [HIGHLIGHT.SCRAPPER]: {
    title: t('scrapper.title', {
      ns: 'highlight',
      defaultValue: 'URL detected',
    }),
    text: t('scrapper.text', {
      ns: 'highlight',
      defaultValue:
        'AI cannot natively process URL information. Click + and select Enter Link to extract data from URL.',
    }),
    onShowCallBack: (): void => {
      analytics.trackEvent('navigation - highlight appear', {
        scenario: 'Scrapper - Url input',
      });
    },
    onSubmitCallback: (updateUserExtraFields): void => {
      analytics.trackEvent('navigation - highlight click', {
        scenario: 'Scrapper - Url input',
      });
      updateUserExtraFields({
        highlights: {
          [HIGHLIGHT.SCRAPPER]: 1,
        },
      });
    },
  },
  [HIGHLIGHT.CHAT_PERSONALITY]: {
    title: t('chat_personality.title', {
      ns: 'highlight',
      defaultValue: 'Choose your assistant role',
    }),
    text: t('chat_personality.text', {
      ns: 'highlight',
      defaultValue:
        'Get more targeted answers by choosing the role you want your AI chat assistant to play.',
    }),
    onShowCallBack: (): void => {
      analytics.trackEvent('navigation - highlight appear', {
        scenario: 'Personality - after 1-st message',
      });
    },
    onSubmitCallback: (updateUserExtraFields): void => {
      analytics.trackEvent('navigation - highlight click', {
        scenario: 'Personality - after 1-st message',
      });
      updateUserExtraFields({
        highlights: {
          [HIGHLIGHT.CHAT_PERSONALITY]: 1,
        },
      });
    },
  },
  [HIGHLIGHT.GOOGLE_SEARCH]: {
    title: t('highlight:google_search_trend.title', 'Want real-time info?'),
    text: t(
      'highlight:google_search_trend.text',
      'AI by default has limited knowledge only on its trained data. If you want to get trends or information after the year 2021 - use the Google function.',
    ),
    onShowCallBack: (): void => {
      analytics.trackEvent('navigation - highlight appear', {
        scenario: 'Google - AI data limit response',
      });
    },
    onSubmitCallback: (updateUserExtraFields): void => {
      analytics.trackEvent('navigation - highlight click', {
        scenario: 'Google - AI data limit response',
      });
      updateUserExtraFields({
        highlights: {
          [HIGHLIGHT.GOOGLE_SEARCH]: 1,
        },
      });
    },
  },
  [HIGHLIGHT.PROMPT_IMPROVER]: {
    title: t('highlight:prompt_improver.title', 'Want better results?'),
    text: t(
      'highlight:prompt_improver.text',
      'Try our Prompt Optimizer and get professional prompts designed specifically for communicating with AI.',
    ),
    onShowCallBack: (): void => {
      analytics.trackEvent('navigation - highlight appear', {
        scenario: 'Improver - volume',
      });
    },
    onSubmitCallback: (updateUserExtraFields): void => {
      analytics.trackEvent('navigation - highlight click', {
        scenario: 'Improver - volume',
      });
      updateUserExtraFields({
        highlights: {
          [HIGHLIGHT.PROMPT_IMPROVER]: 1,
        },
      });
    },
  },
  [HIGHLIGHT.PROMPT_IMPROVER_DISLIKE]: {
    title: t('highlight:prompt_improver.title', 'Want better results?'),
    text: t(
      'highlight:prompt_improver.text',
      'Try our Prompt Optimizer and get professional prompts designed specifically for communicating with AI.',
    ),
    onShowCallBack: (): void => {
      analytics.trackEvent('navigation - highlight appear', {
        scenario: 'Improver - dislike',
      });
    },
    onSubmitCallback: (updateUserExtraFields): void => {
      analytics.trackEvent('navigation - highlight click', {
        scenario: 'Improver - dislike',
      });
      updateUserExtraFields({
        highlights: {
          [HIGHLIGHT.PROMPT_IMPROVER_DISLIKE]: 1,
        },
      });
    },
  },
  [HIGHLIGHT.PROMPT_IMPROVER_EFFECTIVE_USAGE]: {
    title: t('prompt_improver_effective.title', {
      ns: 'highlight',
      defaultValue: 'Effective use of the prompt improver',
    }),
    text: t('prompt_improver_effective.text', {
      ns: 'highlight',
      defaultValue:
        'The prompt improver feature is most productive for improving questions. To get the best results, ask something.',
    }),
    buttonText: t('general:got_it', 'Got it'),
    onShowCallBack: (): void => {
      analytics.trackEvent('navigation - highlight appear', {
        scenario: 'Improver - repetitive text',
      });
    },
    onSubmitCallback: (updateUserExtraFields): void => {
      analytics.trackEvent('navigation - highlight click', {
        scenario: 'Improver - repetitive text',
      });
      updateUserExtraFields({
        highlights: {
          [HIGHLIGHT.PROMPT_IMPROVER_EFFECTIVE_USAGE]: 1,
        },
      });
    },
  },
  ...getToolsCommonHighlightConfig(t),
  ...getChatHighlightConfig(t),
  ...getAAGHighlightConfig(t),
  ...getImageGeneratorHighlightConfig(t),
  ...getAiHumanizerHighlightConfig(t),
  ...getAiDetectorHighlightConfig(t),
  ...getPlagiarismHighlightConfig(t),
  ...getParaphraserHighlightConfig(t),
  ...getAiResearcherHighlightConfig(t),
  ...getFactCheckerHighlightConfig(t),
});
