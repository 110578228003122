import React, { FC, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { sidebar } from '~/core/sidebar';
import RetentionPopover from '~/modules/home/components/RetentionPopover';
import { HomePagePath, HomePageSidebarKey } from '~/modules/home/constants';
import localStorage from '~/services/storage/localStorage';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';

const alreadyShowRetentionHomeScreenKey = 'alreadyShowRetentionHomeScreen7';
const alreadyShowRetentionNotifyKey = 'alreadyShowRetentionModal';

type Props = {
  children?: React.ReactNode;
};

const HomeScreenRetention: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const profile = useAppSelector((state) => state.profile);
  const [isShowedNotification, setIsShowedNotification] = useState(false);
  const { updateUserExtraFields } = useActions();

  useEffect(() => {
    setIsShowedNotification(false);

    if (pathname === HomePagePath) {
      sidebar.setLabel(HomePageSidebarKey, (): string => '');
      return;
    }
  }, [pathname]);

  useEffect(() => {
    if (
      profile.response_count_total > 0 &&
      !profile.extra_fields.notifications[alreadyShowRetentionHomeScreenKey]
    ) {
      sidebar.setLabel(HomePageSidebarKey, (): string => '1');

      if (!localStorage.getByKey(alreadyShowRetentionNotifyKey)) {
        setIsShowedNotification(true);
        localStorage.setByKey(alreadyShowRetentionNotifyKey, '1');
      }

      updateUserExtraFields({
        notifications: {
          ...profile.extra_fields.notifications,
          [alreadyShowRetentionHomeScreenKey]: true,
        },
      });
    }
  }, [profile]);

  const handleClose = (): void => {
    localStorage.setByKey(alreadyShowRetentionNotifyKey, '1');
    setIsShowedNotification(false);
  };

  return (
    <>
      {isShowedNotification && <RetentionPopover onClickClose={handleClose} />}
      {children}
    </>
  );
};

export default HomeScreenRetention;
