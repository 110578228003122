import { TFunction } from 'i18next';

import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import { REACTION } from '~/modules/reactions/types';
import { ExtendToolExecutionType } from '~/modules/tools/hooks/useToolExecutionItemsData';
import analytics from '~/services/analytics';

export const ParaphraserTitle = 'Paraphrase Text';

export const EXAMPLE_RESPONSE: ExtendToolExecutionType = {
  id: 'example_response',
  text:
    'A gold standard refers to a monetary framework where the primary unit of account is tied to a specific amount of gold. This system served as the foundation for international finance from the 1870s until the early 1920s, and again from the late 1920s through 1932, continuing until 1971 when the United States ended the convertibility of its dollar into gold, thereby concluding the Bretton Woods arrangement. Despite this shift, many countries continue to maintain significant gold reserves.\n' +
    '\n' +
    'Historically, both the silver standard and bimetallism were more prevalent than the gold standard. The transition to a global monetary system anchored in gold was influenced by chance events, network effects, and historical precedents. Notably, Great Britain inadvertently established a de facto gold standard in 1717 when Isaac Newton, who was then in charge of the Royal Mint, set an exchange rate that undervalued silver compared to gold, leading to the withdrawal of silver coins from circulation. As Great Britain emerged as a dominant financial and trade power during the 19th century, other nations increasingly adopted its monetary practices.\n' +
    '\n' +
    "The gold standard saw significant decline during the Great Depression, although it was later reintroduced in a limited capacity as part of the post-World War II Bretton Woods system. Its abandonment was largely due to its tendency towards instability and the limitations it placed on government action; fixed exchange rates restricted governments' ability to implement expansionary measures aimed at reducing unemployment during economic downturns.",
  reaction: REACTION.NONE,
  symbols: 1660,
  words: 248,
  created_at: '2025-02-18T18:50:06.686461Z',
  updated_at: '2025-02-18T18:50:11.831728Z',
  ai_edit_items: [],
  children: [],
  user_inputs: {
    original_text:
      "A gold standard is a monetary system in which the standard economic unit of account is based on a fixed quantity of gold. The gold standard was the basis for the international monetary system from the 1870s to the early 1920s, and from the late 1920s to 1932 as well as from 1944 until 1971 when the United States unilaterally terminated convertibility of the US dollar to gold, effectively ending the Bretton Woods system.Many states nonetheless hold substantial gold reserves.\n\nHistorically, the silver standard and bimetallism have been more common than the gold standard.The shift to an international monetary system based on a gold standard reflected accident, network externalities, and path dependence.Great Britain accidentally adopted a de facto gold standard in 1717 when Isaac Newton, then-master of the Royal Mint, set the exchange rate of silver to gold too low, thus causing silver coins to go out of circulation. As Great Britain became the world's leading financial and commercial power in the 19th century, other states increasingly adopted Britain's monetary system.\n\nThe gold standard was largely abandoned during the Great Depression before being reinstated in a limited form as part of the post-World War II Bretton Woods system. The gold standard was abandoned due to its propensity for volatility, as well as the constraints it imposed on governments: by retaining a fixed exchange rate, governments were hamstrung in engaging in expansionary policies to, for example, reduce unemployment during economic recessions.",
    tone_of_voice: 'Neutral',
    output_counter: 1,
    paraphrase_level: 'minimum',
  },
  demo_mode: true,
};

export const IsShowedParaphraserTutorial = 'is_showed_paraphraser_tutorial';

export const getParaphraserHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.PARAPHRASER_LEVEL_MIN
  | HIGHLIGHT.PARAPHRASER_LEVEL_RECOMMENDED
  | HIGHLIGHT.PARAPHRASER_LEVEL_MAX
  | HIGHLIGHT.PARAPHRASER_TONE
  | HIGHLIGHT.PARAPHRASER_VARIANTS
  | HIGHLIGHT.PARAPHRASER_CHECK_PLAGIARISM_BUTTON
  | HIGHLIGHT.PARAPHRASER_AI_EDIT_BUTTON,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.PARAPHRASER_LEVEL_MIN]: {
      title: t(
        'highlight:paraphraser.level_min.title',
        'Minimum Paraphrase Level',
      ),
      text: t(
        'highlight:paraphraser.level_min.text',
        'Slight changes to the text with minor deviations from the original',
      ),
      placement: 'top-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 1,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: ParaphraserTitle,
          tip_number: 1,
        });
      },
    },
    [HIGHLIGHT.PARAPHRASER_LEVEL_RECOMMENDED]: {
      title: t('highlight:paraphraser.level_recommended.title', 'Recommended'),
      text: t(
        'highlight:paraphraser.level_recommended.text',
        'Balanced mode with moderate changes to the text',
      ),
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 2,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: ParaphraserTitle,
          tip_number: 2,
        });
      },
    },
    [HIGHLIGHT.PARAPHRASER_LEVEL_MAX]: {
      title: t('highlight:paraphraser.level_max.title', 'Maximum'),
      text: t(
        'highlight:paraphraser.level_max.text',
        'Substantial paraphrasing of the text, making the result very different from the initial text but maintaining the original meaning',
      ),
      placement: 'top-end',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 3,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: ParaphraserTitle,
          tip_number: 3,
        });
      },
    },
    [HIGHLIGHT.PARAPHRASER_TONE]: {
      title: t('highlight:paraphraser.tone.title', 'Output Text Tone'),
      text: t(
        'highlight:paraphraser.tone.text',
        'Choose the tone of the output text to adjust the narrative, such as “Informative” or “Casual”',
      ),
      placement: 'top-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 4,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: ParaphraserTitle,
          tip_number: 4,
        });
      },
    },
    [HIGHLIGHT.PARAPHRASER_VARIANTS]: {
      title: t('highlight:paraphraser.variants.title', 'Output Text Variants'),
      text: t(
        'highlight:paraphraser.variants.text',
        'Receive up to 5 response variations for your one request',
      ),
      placement: 'top-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 5,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: ParaphraserTitle,
          tip_number: 5,
        });
      },
    },
    [HIGHLIGHT.PARAPHRASER_CHECK_PLAGIARISM_BUTTON]: {
      title: t(
        'highlight:paraphraser.plagiarism_button.title',
        'Check For Plagiarism',
      ),
      text: t(
        'highlight:paraphraser.plagiarism_button.text',
        'Scan the text to double-check if it is being flagged as Plagiarism',
      ),
      placement: 'top-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 6,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: ParaphraserTitle,
          tip_number: 6,
        });
      },
    },
    [HIGHLIGHT.PARAPHRASER_AI_EDIT_BUTTON]: {
      title: t('highlight:paraphraser.ai_edit_button.title', 'AI Edit'),
      text: t(
        'highlight:paraphraser.ai_edit_button.text',
        'This feature allows you to ask AI to do different things with text, such as summarize, change tone, or do anything else you ask',
      ),
      placement: 'top-start',
      buttonText: t('general:finish', 'Finish'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: ParaphraserTitle,
          tip_number: 7,
        });
      },
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [IsShowedParaphraserTutorial]: true },
        });
        analytics.trackEvent('tutorial - finished', {
          tool: ParaphraserTitle,
        });
      },
    },
  };
};
