import React, { FC, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { OrderShort } from '@solidgate/react-sdk';
import clsx from 'clsx';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import Checkout from '~/components/organisms/Checkout';
import Agreement from '~/components/organisms/PaymentForm/components/Agreement';
import Guarantee from '~/components/organisms/PaymentForm/components/Guarantee';
import OrderDescription from '~/components/organisms/PaymentForm/components/OrderDescription';
import PaymentInfo from '~/components/organisms/PaymentForm/components/PaymentInfo';
import PaymentSystems from '~/components/organisms/PaymentForm/components/PaymentSystems';
import { ModalStateType } from '~/contexts/modal/ModalContext';
import { getProductName } from '~/helpers/translation';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { AnalyticPaymentMethods, PAYMENT_METHODS } from '~/types/payment';
import { ProductType, REGULARITY } from '~/types/product';

import styles from './styles.module.scss';

export const paymentFormModalConfig: Partial<ModalStateType> = {
  isFullScreen: false,
  maxWidth: '800px',
  closeButtonColor: '#B3B3B3',
  pageViewMobile: true,
};

export const paymentStripeFormModalConfig: Partial<ModalStateType> = {
  isFullScreen: false,
  maxWidth: '1200px',
  closeButtonColor: '#B3B3B3',
  pageViewMobile: true,
};

type Props = {
  userId: string;
  product: ProductType;
  onSuccessPayment: () => void | Promise<void>;
  onCreateCardIntentError?: (errorMessage: string) => void;
  paymentApi?: {
    createPaymentIntentCard: (
      user_id: string,
      product_id: string,
      geo_country: string,
    ) => Promise<{
      merchant: string;
      payment_intent: string;
      signature: string;
    }>;
    createPaymentIntentPaypal: (
      user_id: string,
      product_id: string,
    ) => Promise<{
      order: Record<string, any>;
      order_metadata: any;
      pay_form: Record<string, any>;
    }>;
  };
  purchaseSource?: string;
};

const PaymentForm: FC<Props> = ({
  userId,
  product,
  purchaseSource,
  onSuccessPayment,
  onCreateCardIntentError,
  paymentApi = {
    createPaymentIntentCard: Api.createPaymentIntentCard.bind(Api),
    createPaymentIntentPaypal: Api.createPaymentIntentPaypal.bind(Api),
  },
}) => {
  const { t } = useTranslation('general');

  const [isFormShown, setIsFormShown] = useState(false);

  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handlePaymentSuccess = async (order: OrderShort): Promise<void> => {
    analytics.trackPurchase('platform payment - success', {
      user_id: userId,
      value: order.amount ? order.amount / 100 : 0,
      product_id: product.id,
      product_name: product.name,
      order_id: order.order_id ? order.order_id : '',
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });

    await onSuccessPayment();
  };

  const handlePaymentFail = (
    message: string,
    method: AnalyticPaymentMethods,
  ): void => {
    analytics.trackEvent('platform checkout - error', {
      method,
      error: message,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  const handleCheckoutSubmit = (method: AnalyticPaymentMethods): void => {
    analytics.trackEvent('platform checkout - submit', {
      method,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  const handlePaymentMethodChange = (type: PAYMENT_METHODS): void => {
    analytics.trackEvent('platform checkout - navigation', {
      method: type,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  useEffect(() => {
    analytics.trackEvent('platform checkout - view', {
      user_id: userId,
      product_id: product.id,
      product_name: product.name,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  }, []);

  const regularityText =
    product.regularity === REGULARITY.ONETIME
      ? t('one_time_payment', 'One time payment')
      : product.has_trial_period
        ? t('general:then_price_per_period', {
            period: t(`general:${product.regularity}`, product.regularity),
            price: `$${product.new_price_ui / 100}`,
            defaultValue: 'Then {{price}} per {{period}}',
          })
        : t('general:per_period', {
            period: t(`general:${product.regularity}`, product.regularity),
            defaultValue: 'Per {{period}}',
          });

  return (
    <>
      {!isFormShown && <Loader />}

      <div className={styles.outer_wrapper}>
        <div
          className={clsx(styles.inner_wrapper, {
            [styles.is_shown]: isFormShown,
          })}
        >
          {isDesktop && (
            <div className={styles.description}>
              <OrderDescription
                productId={product.id}
                productName={getProductName(product.id, product.name, t)}
                productRegularity={product.regularity}
                isPopular={product.is_popular_ui}
              />
            </div>
          )}

          <div className={styles.form_outer}>
            <div className={styles.form_inner}>
              <Typography
                className={styles.title}
                variant={Typographies.LABEL_LARGE}
                component="h3"
              >
                {t(
                  'subscription:payment_form.select_payment_method',
                  'Select your payment method',
                )}
              </Typography>

              <Checkout
                onPaymentSuccess={handlePaymentSuccess}
                onPaymentFail={handlePaymentFail}
                onCheckoutSubmit={handleCheckoutSubmit}
                onPaymentMethodChange={handlePaymentMethodChange}
                onFormReady={() => setIsFormShown(true)}
                onCreateCardIntentError={onCreateCardIntentError}
                paymentApi={{
                  createPaymentIntentCard: (geo_country) =>
                    paymentApi.createPaymentIntentCard(
                      userId,
                      product.id,
                      geo_country,
                    ),
                  createPaymentIntentPaypal: () =>
                    paymentApi.createPaymentIntentPaypal(userId, product.id),
                }}
              />

              <Guarantee />

              <PaymentInfo
                id={product.id}
                price={String(
                  ((product.trial_amount || product.price) / 100).toFixed(2),
                )}
                title={getProductName(product.id, product.name, t)}
                regularity={regularityText}
              />
              <PaymentSystems />
            </div>
            <Agreement />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
