import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';
import { IconButton, InputAdornment } from '@mui/material';

import {
  StyledTextField,
  StyledTextFieldProps,
} from '~/components/atoms/inputs/TextField';

export type PasswordFieldProps = StyledTextFieldProps;

const PasswordField: FC<PasswordFieldProps> = ({ ...props }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <StyledTextField
      {...props}
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={(): void => setIsPasswordVisible(!isPasswordVisible)}
              edge="end"
            >
              <Icon
                icon={
                  isPasswordVisible
                    ? 'material-symbols:visibility-off-outline'
                    : 'material-symbols:visibility-outline'
                }
                color="#000000"
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
