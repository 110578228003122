import React, { FC } from 'react';

import {
  TextField as MuiTextField,
  styled,
  TextFieldProps,
} from '@mui/material';

import { getCountText } from '~/components/atoms/inputs/helpers/functions';
import { colors } from '~/theme/colors';

export type StyledTextFieldProps = TextFieldProps;

export type TextFieldCustomProps = TextFieldProps & {
  maxCountIndicator?: number;
  minCountWarning?: number;
};

export const StyledTextField = styled(MuiTextField, {
  name: 'StyledTextField',
})(() => ({
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: colors.white,

    '&.MuiInputBase-colorWarning.Mui-focused + .MuiFormHelperText-root': {
      color: colors.warning_50,
    },

    '& .MuiList-root': {
      backgroundColor: colors.white,
    },
  },

  '& legend': {
    display: 'none',
  },

  '& .MuiInputLabel-root': {
    color: colors.black,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '8px',
    position: 'static',
    transform: 'unset',

    '.MuiFormLabel-asterisk': {
      display: 'none',
    },

    '&.Mui-error': {
      color: colors.error_40,
    },
  },

  '& .MuiInputBase-input': {
    padding: '14px 16px',
    fontSize: '14px',
    lineHeight: '20px',

    '&::placeholder': {
      color: '#8C8C90',
      opacity: 1,
    },
  },

  '& .MuiInputBase-inputSizeSmall': {
    padding: '10px 12px',
    fontSize: '14px',
    lineHeight: '20px',

    '&::placeholder': {
      color: '#8C8C90',
      opacity: 1,
    },
  },

  '& .MuiInputBase-multiline': {
    padding: '14px 16px',

    '& .MuiInputBase-input': {
      padding: '0px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    top: 0,
    borderColor: '#E2E2EC',

    '&.Mui-error': {
      borderColor: colors.error_40,
    },
  },

  '&.Mui-focused': {
    '& fieldset': {
      borderWidth: '2px',
    },
  },

  '& .MuiInputAdornment-root': {
    margin: 0,
  },

  '& .MuiInputBase-adornedStart': {
    padding: 0,
  },

  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    lineHeight: '20px',
    margin: '8px 12px 0',
  },
}));

const TextField: FC<TextFieldCustomProps> = ({
  maxCountIndicator,
  minCountWarning,
  color,
  value,
  ...props
}) => {
  const { countText, minCountText } = getCountText(
    value as string,
    minCountWarning,
    maxCountIndicator,
  );

  const inputColor = minCountText ? 'warning' : null;

  return (
    <StyledTextField
      value={value}
      helperText={minCountText || countText}
      {...(inputColor ? { color: color || inputColor } : {})}
      {...props}
    />
  );
};

export default TextField;
