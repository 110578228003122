import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import StarsAnimatedButton from '~/components/atoms/buttons/StarsAnimatedButton';
import { getProductName } from '~/helpers/translation';
import {
  ANNUAL_PLAN_DISCOUNT,
  getDescriptionBenefits,
  OLD_MONTHLY_PRICE,
  OLDER_MONTHLY_PRICE,
} from '~/modules/settings/components/modals/UpgradeSubscriptionModal/variants/constants';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';

import styles from './styles.module.scss';

type Props = {
  offeredProduct: ProductType;
  onClick: () => void;
};

const OrderDescription: FC<Props> = ({ offeredProduct, onClick }) => {
  const { user_subscription, user_product } = useAppSelector(
    (state) => state.profile,
  );

  const { t } = useTranslation('general');

  const benefits = getDescriptionBenefits({
    t,
    isTrial: user_subscription.is_trial,
  });

  return (
    <div className={styles.container}>
      <Typography
        variant={Typographies.LABEL_LARGE}
        component="div"
        className={styles.label}
      >
        {t('modal:upgrade_sub.label', {
          amount: ANNUAL_PLAN_DISCOUNT,
          defaultValue: 'Save ${{amount}} Annually',
        })}
      </Typography>

      <Typography
        variant={Typographies.TITLE_LARGE}
        component="div"
        className={styles.title}
      >
        <Icon icon="material-symbols:all-inclusive" width="32" height="32" />
        <span>{getProductName(offeredProduct.id, offeredProduct.name, t)}</span>
      </Typography>

      <div className={styles.price}>
        <Typography
          variant={Typographies.LABEL_LARGE}
          component="div"
          className={styles.price__old}
        >
          ${OLDER_MONTHLY_PRICE}
        </Typography>

        <Icon
          icon="material-symbols:arrow-forward-rounded"
          width="20"
          height="20"
          color={colors.neutral_30}
        />

        <Typography
          variant={Typographies.LABEL_LARGE}
          component="div"
          className={styles.price__old}
        >
          ${OLD_MONTHLY_PRICE}
        </Typography>

        <Icon
          icon="material-symbols:arrow-forward-rounded"
          width="20"
          height="20"
          color={colors.neutral_30}
        />

        <Typography
          variant={Typographies.TITLE_LARGE}
          component="div"
          className={styles.price__new}
        >
          ${(offeredProduct.price / 100 / 12).toFixed(2)}/
          {t('month', 'Month').toLowerCase()}
        </Typography>
      </div>

      <div className={styles.benefits__list}>
        {benefits.map((item) => {
          return (
            <Typography
              key={item}
              variant={Typographies.BODY_LARGE}
              component="div"
              className={styles.benefits__item}
            >
              <Icon
                icon="material-symbols:check-rounded"
                width="24"
                height="24"
                color={colors.green_50}
              />
              <span>{item}</span>
            </Typography>
          );
        })}
      </div>

      <StarsAnimatedButton text={t('upgrade', 'Upgrade')} onClick={onClick} />

      {user_subscription.is_trial && (
        <Typography
          variant={Typographies.BODY_MEDIUM}
          component="div"
          className={styles.trial}
        >
          {t('modal:upgrade_sub.trial', {
            trial_duration: user_product.trial_period,
            defaultValue:
              'Alternatively, at the end of the {{trial_duration}}-day introductory period, your plan will automatically upgrade to the default plan',
          })}{' '}
          (${(user_product.price / 100).toFixed(2)}/
          {t(
            `${user_product.regularity}`,
            user_product.regularity,
          ).toLowerCase()}
          )
        </Typography>
      )}
    </div>
  );
};

export default OrderDescription;
