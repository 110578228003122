import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { IconButton, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';
import RobotImg from '../assets/robot.webp';
import RobotPreviewImg from '../assets/robot_preview.png';

const getListItems = (t: TFunction): string[] => {
  return [
    t(
      'ai_researcher.intro_modal.list[0]',
      'Advanced search capabilities across trusted databases',
    ),
    t('ai_researcher.intro_modal.list[1]', 'Automatic citation generation'),
    t('ai_researcher.intro_modal.list[2]', 'In-depth resource summarization'),
    t('ai_researcher.intro_modal.list[3]', 'Built in document formatting'),
  ];
};

type Props = {
  onSubmit: () => void;
  onRefuse: () => void;
};

const IntroModal: FC<Props> = ({ onSubmit, onRefuse }) => {
  const { t } = useTranslation('tools');

  const list = getListItems(t);

  return (
    <div className={styles.container}>
      <div className={styles.close_button}>
        <IconButton onClick={onRefuse} size="small">
          <Icon
            icon="material-symbols:close"
            width="24"
            color="colors.neutral_30"
            className={styles.close_icon}
          />
        </IconButton>
      </div>

      <div className={styles.image}>
        <Image src={RobotImg} preview={RobotPreviewImg} />
      </div>

      <Typography
        variant={Typographies.TITLE_LARGE}
        className={styles.title}
        component="div"
      >
        {t('ai_researcher.intro_modal.title', 'Introducing AI Researcher!')}
      </Typography>

      <Typography
        variant={Typographies.TITLE_MEDIUM}
        className={styles.subtitle}
        component="div"
      >
        {t(
          'ai_researcher.intro_modal.subtitle',
          'An AI-powered research tool designed to streamline the discovery and analysis of information',
        )}
        .
      </Typography>

      <div className={styles.list}>
        {list.map((el) => {
          return (
            <div className={styles.list__item} key={el}>
              <Icon
                icon="material-symbols:check-rounded"
                width="24"
                height="24"
                color={colors.green_50}
                className={styles.list__item_icon}
              />

              <Typography
                variant={Typographies.TITLE_MEDIUM}
                className={styles.list__item_text}
                component="div"
              >
                {el}
              </Typography>
            </div>
          );
        })}
      </div>

      <div className={styles.buttons_container}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={styles.button}
        >
          {t('general:discover', 'Discover')}
        </Button>

        <Button
          variant="text"
          color="primary"
          onClick={onRefuse}
          className={styles.button}
        >
          {t('general:later', 'Later')}
        </Button>
      </div>
    </div>
  );
};

export default IntroModal;
