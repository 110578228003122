import DateService from '~/services/Date';
import { REGULARITY } from '~/types/product';

export const checkIsSubUnlimited = (
  isStripe: boolean,
  isTrial: boolean,
  regularity: REGULARITY,
): boolean => {
  return !isStripe && regularity === REGULARITY.YEAR && !isTrial;
};

export const getTrialDistancePassed = (
  startedAt: string,
  trialPeriod: number,
): number => {
  const startDate = DateService.toDate(new Date(startedAt));
  const todayDate = DateService.toDate(Date.now());

  const difference = DateService.differenceInDays(todayDate, startDate);

  return difference < trialPeriod ? difference : trialPeriod;
};
