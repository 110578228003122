import React, { FC } from 'react';

import MainLayout from '~/components/layouts/MainLayout';
import MainLayoutSimple from '~/components/layouts/MainLayout/indexSimple';
import SimpleHeaderLogoLayout from '~/components/layouts/SimpleHeaderLogoLayout';
import AuthAnalyticsWrapper from '~/middlewares/AuthAnalyticsWrapper';
import CheckoutOpeningMiddleware from '~/middlewares/CheckoutOpeningMiddleware';
import OnboardingMiddleware from '~/middlewares/OnboardingMiddleware';
import PWAModalMiddleware from '~/middlewares/PWAModalMiddleware';
import SubModalsMiddleware from '~/middlewares/SubModalsMiddleware';
import HomeScreenRetentionMiddleware from '~/modules/home/middlewares/HomeScreenRetention';
import NavigationLayout from '~/modules/settings/components/NavigationLayout';

type LayoutProps = {
  children?: React.ReactNode;
};

export const withLayout =
  <T extends Record<any, any>>(
    Layout: FC<LayoutProps>,
    Component: FC<T>,
  ): FC<T> =>
  ({ ...props }: T): JSX.Element => (
    <Layout>
      <Component {...props} />
    </Layout>
  );

// layouts
const withMainLayout = (component: FC): FC => withLayout(MainLayout, component);
const withMainSimpleLayout = (component: FC): FC =>
  withLayout(MainLayoutSimple, component);

const withSettingsNavigationLayout = (component: FC): FC =>
  withLayout(NavigationLayout, component);

const withOnboardingLayout = (component: FC): FC =>
  withLayout(OnboardingMiddleware, component);

const withHomeScreenRetentionLayout = (component: FC): FC =>
  withLayout(HomeScreenRetentionMiddleware, component);

const withPWAModalLayout = (component: FC): FC =>
  withLayout(PWAModalMiddleware, component);

const withSubModalsLayout = (component: FC): FC =>
  withLayout(SubModalsMiddleware, component);

const withSimpleHeaderLogoLayout = (component: FC): FC =>
  withLayout(SimpleHeaderLogoLayout, component);

const withCheckoutOpeningLayout = (component: FC): FC =>
  withLayout(CheckoutOpeningMiddleware, component);

const withAuthAnalyticsLayout = (component: FC): FC =>
  withLayout(AuthAnalyticsWrapper, component);

export {
  withMainLayout,
  withMainSimpleLayout,
  withSettingsNavigationLayout,
  withOnboardingLayout,
  withHomeScreenRetentionLayout,
  withPWAModalLayout,
  withSubModalsLayout,
  withSimpleHeaderLogoLayout,
  withCheckoutOpeningLayout,
  withAuthAnalyticsLayout,
};
