import { TFunction } from 'i18next';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSimpleHeaderLogoLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  ExtensionAuthPath,
  ExtensionOnboardingPath,
  ExtensionPath,
  ExtensionPostDeletePath,
  ExtensionSidebarKey,
} from '~/modules/extension/constants';
import analytics from '~/services/analytics';
import extension from '~/services/Extension';

const Onboarding = lazyComponentLoader(() => import('./views/Onboarding'));

const Extension = lazyComponentLoader(() => import('./views/Extension'));

const ExtensionPostDelete = lazyComponentLoader(
  () => import('./views/PostDelete'),
);

const ExtensionAuth = lazyComponentLoader(() => import('./views/Auth'));

const getTitle = (t: TFunction): string =>
  `${extension.getTitle()} ${t('sidebar:extension', 'Extension')}`;
const Icon = extension.getIcon();
const isExtensionAvailable = extension.checkIsExtensionAvailable();

const ExtensionOnboardingRoute: RouteItemType = {
  Component: flowRight(withSimpleHeaderLogoLayout)(Onboarding),
  path: ExtensionOnboardingPath,
  getTitle,
};

const ExtensionRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Extension),
  checkAvailability: () => isExtensionAvailable,
  path: ExtensionPath,
  getTitle,
};

const ExtensionPostDeleteRoute: RouteItemType = {
  Component: ExtensionPostDelete,
  path: ExtensionPostDeletePath,
  getTitle,
  checkAvailability: () => isExtensionAvailable,
};

const ExtensionAuthRoute: RouteItemType = {
  Component: ExtensionAuth,
  path: ExtensionAuthPath,
  getTitle,
};

router.addPrivateRoutes([
  ExtensionOnboardingRoute,
  ExtensionRoute,
  ExtensionAuthRoute,
]);

router.addUnAuthorizedRoutes([ExtensionPostDeleteRoute]);

sidebar.addItems({
  a: [
    {
      order: 5,
      key: ExtensionSidebarKey,
      path: ExtensionRoute.path,
      getTitle: ExtensionRoute.getTitle,
      onClickCb: (): void => {
        analytics.trackEvent('side_menu_click', {
          button: 'chrome_extension',
        });
      },
      checkAvailability: (): boolean => isExtensionAvailable,
      // @ts-ignore
      Icon,
    },
  ],
});
