import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  DISCOUNT_PERCENT_ANNUAL_SUB_OFFER,
  PRODUCT,
} from '~/constants/products';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  id: string;
  price: string;
  title: string;
  regularity?: string;
  description?: string;
};

const PaymentInfo: FC<Props> = ({
  id,
  price,
  title,
  regularity,
  description,
}) => {
  const { t } = useTranslation('general');
  return (
    <div className={styles.container}>
      {id === PRODUCT.ANNUAL_SUB_OFFER && (
        <div className={styles.discount}>
          -${DISCOUNT_PERCENT_ANNUAL_SUB_OFFER}
        </div>
      )}

      <div className={styles.flex}>
        <Typography
          className={styles.title}
          variant={Typographies.BODY_LARGE}
          component="p"
        >
          {title}
        </Typography>

        <div className={styles.box}>
          <Typography
            className={styles.price}
            variant={Typographies.BODY_LARGE}
            component="p"
          >
            {t('total', 'Total')}: ${price}
          </Typography>

          {regularity && (
            <Typography
              className={styles.regularity}
              variant={Typographies.TITLE_SMALL}
              component="p"
            >
              {regularity}
            </Typography>
          )}
        </div>
      </div>

      {description && (
        <Typography
          className={styles.description}
          variant={Typographies.TITLE_SMALL}
          component="p"
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

export default PaymentInfo;
