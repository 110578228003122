import { TFunction } from 'i18next';

import { LETTER_IDX_MAP } from '~/modules/tools/views/QuizGenerator/constants';
import {
  ExtendedOptionType,
  OptionType,
  ProcessedQuizOptions,
  QuestionItemType,
  QuestionType,
} from '~/modules/tools/views/QuizGenerator/types';
import { ProfileType } from '~/types/profile';

export const checkIsQuizGeneratorAccessible = (
  profile: ProfileType,
): boolean => {
  return (
    profile.backend_ab_tests?.['experiment_quiz_generator_v1'] === 'b_test'
  );
};

export enum STATISTIC_KEY {
  CORRECT = 'correct',
  WRONG = 'wrong',
  SCORE = 'score',
  QUESTIONS = 'questions',
  REMAINING = 'remaining',
}

export const checkIsExecutionCompleted = (
  quiz: QuestionItemType[],
): boolean => {
  return !quiz.find(({ is_completed }) => !is_completed);
};

export const getStatistics = (
  quiz: QuestionItemType[],
  t: TFunction,
): {
  key: STATISTIC_KEY;
  text: string;
  value: string | number;
}[] => {
  const completeItems = quiz.filter(({ is_completed }) => is_completed);
  const remainingItems = quiz.filter(({ is_completed }) => !is_completed);

  const { correctItemsCount, wrongItemCount } = completeItems.reduce(
    (acc, item) => {
      let correctOptionsSelected = 0;
      let incorrectOptionsSelected = 0;
      let totalCorrectOptions = 0;

      item.options.forEach((option) => {
        if (option.is_correct) {
          totalCorrectOptions++;
        }

        if (option.is_correct && option.is_selected) {
          correctOptionsSelected++;
        }
        if (!option.is_correct && option.is_selected) {
          incorrectOptionsSelected++;
        }
      });

      const isWrong =
        (item.type === QuestionType.SINGLE &&
          correctOptionsSelected !== totalCorrectOptions) ||
        (item.type === QuestionType.MULTI &&
          (correctOptionsSelected !== totalCorrectOptions ||
            incorrectOptionsSelected > 0));

      if (isWrong) {
        acc.wrongItemCount++;
      } else {
        acc.correctItemsCount++;
      }

      return acc;
    },
    { correctItemsCount: 0, wrongItemCount: 0 },
  );

  return [
    {
      key: STATISTIC_KEY.CORRECT,
      text: t('general:correct', 'Correct'),
      value: correctItemsCount,
    },
    {
      key: STATISTIC_KEY.WRONG,
      text: t('general:wrong', 'Wrong'),
      value: wrongItemCount,
    },
    ...(remainingItems.length > 0
      ? [
          {
            key: STATISTIC_KEY.REMAINING,
            text: t('general:question_remaining', 'Questions Remaining'),
            value: remainingItems.length,
          },
        ]
      : [
          {
            key: STATISTIC_KEY.SCORE,
            text: t('general:score', 'Score'),
            value: correctItemsCount
              ? Math.round((correctItemsCount / quiz.length) * 100)
              : 0,
          },
          {
            key: STATISTIC_KEY.QUESTIONS,
            text: t('general:questions', 'Questions'),
            value: quiz.length,
          },
        ]),
  ];
};

export const processQuizOptions = (
  options: OptionType[],
  type: QuestionType,
): ProcessedQuizOptions => {
  let correctOptionsSelected = 0;
  let incorrectOptionsSelected = 0;
  let totalCorrectOptions = 0;

  const result = options.reduce(
    (acc, option, option_idx) => {
      const letter = LETTER_IDX_MAP[option_idx] || '';
      const updatedOption: ExtendedOptionType = {
        ...option,
        letter,
      };

      if (updatedOption.is_selected) {
        acc.userAnswers.push(updatedOption);
      }

      if (updatedOption.is_correct) {
        totalCorrectOptions++;
        if (updatedOption.is_selected) {
          correctOptionsSelected++;
          acc.selectedCorrect = acc.selectedCorrect && true;
        }

        acc.correctAnswers.push(updatedOption);
      }

      if (!updatedOption.is_correct && updatedOption.is_selected) {
        incorrectOptionsSelected++;
        acc.selectedIncorrect = true;
      }

      return acc;
    },
    {
      userAnswers: [] as ExtendedOptionType[],
      correctAnswers: [] as ExtendedOptionType[],
      selectedIncorrect: false as boolean,
      selectedCorrect: true as boolean,
    },
  );

  const isWrong =
    (type === QuestionType.SINGLE &&
      correctOptionsSelected !== totalCorrectOptions) ||
    (type === QuestionType.MULTI &&
      (correctOptionsSelected !== totalCorrectOptions ||
        incorrectOptionsSelected > 0));

  if (isWrong) {
    result.selectedCorrect = false;
  }

  return result;
};
