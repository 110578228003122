import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { OrderShort } from '@solidgate/react-sdk';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import Checkout from '~/components/organisms/Checkout';
import SecuredSystemsV2Img from '~/components/organisms/PaymentForm/assets/secured_systems_v2.webp';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { AnalyticPaymentMethods, PAYMENT_METHODS } from '~/types/payment';
import { ProductType } from '~/types/product';

import styles from './styles.module.scss';

type Props = {
  product: ProductType;
  onSuccessPayment: () => void | Promise<void>;
  onFormReady: () => void;
  purchaseSource?: string;
};

const PaymentForm: FC<Props> = ({
  product,
  onSuccessPayment,
  onFormReady,
  purchaseSource,
}) => {
  const { id } = useAppSelector((state) => state.profile);

  const { t } = useTranslation('general');
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handlePaymentSuccess = async (order: OrderShort): Promise<void> => {
    analytics.trackPurchase('platform payment - success', {
      user_id: id,
      value: order.amount ? order.amount / 100 : 0,
      product_id: product.id,
      product_name: product.name,
      order_id: order.order_id ? order.order_id : '',
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });

    await onSuccessPayment();
  };

  const handlePaymentFail = (
    message: string,
    method: AnalyticPaymentMethods,
  ): void => {
    analytics.trackEvent('platform checkout - error', {
      method,
      error: message,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  const handleCheckoutSubmit = (method: AnalyticPaymentMethods): void => {
    analytics.trackEvent('platform checkout - submit', {
      method,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  const handlePaymentMethodChange = (type: PAYMENT_METHODS): void => {
    analytics.trackEvent('platform checkout - navigation', {
      method: type,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  return (
    <div className={styles.container}>
      {!isDesktop && (
        <Typography
          className={styles.title}
          variant={Typographies.TITLE_MEDIUM}
          component="h3"
        >
          {t(
            'subscription:payment_form.select_payment_method',
            'Select your payment method',
          )}
        </Typography>
      )}

      <Checkout
        onPaymentSuccess={handlePaymentSuccess}
        onPaymentFail={handlePaymentFail}
        onCheckoutSubmit={handleCheckoutSubmit}
        onPaymentMethodChange={handlePaymentMethodChange}
        onFormReady={onFormReady}
        paymentApi={{
          createPaymentIntentCard: (geo_country) =>
            Api.createPaymentIntentCard(id, product.id, geo_country),
          createPaymentIntentPaypal: () =>
            Api.createPaymentIntentPaypal(id, product.id),
        }}
      />

      <div className={styles.guarantee}>
        <img
          src={SecuredSystemsV2Img}
          alt=""
          className={styles.guarantee__img}
        />

        <Typography
          className={styles.guarantee__text}
          variant={Typographies.LABEL_MEDIUM}
          component="h3"
        >
          {t('payments_secured', 'Payments secured')}
        </Typography>
      </div>
    </div>
  );
};

export default PaymentForm;
