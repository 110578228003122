// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UeKFI1GNnkyZVpo7CNP5{position:relative;padding:10px 0;margin-bottom:-10px}.Fiv4wnz_AVZ1XjhO3zKp{background:linear-gradient(270deg, #00B39B, #3B71F7) !important}.v9J9TYRyIXKrH5wXpo6R{position:absolute;top:0;left:0;width:100%;height:100%}.zjhRQGPX0lw_OYDkMITz{position:absolute;top:10px;left:0;height:calc(100% - 20px);width:100%;cursor:pointer}.pKHEXHcq4aP7daxlj515{pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/atoms/buttons/StarsAnimatedButton/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,cAAA,CACA,mBAAA,CAGF,sBACE,+DAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,MAAA,CACA,wBAAA,CACA,UAAA,CACA,cAAA,CAEA,sBACE,mBAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.container {\n  position: relative;\n  padding: 10px 0;\n  margin-bottom: -10px;\n}\n\n.button {\n  background: linear-gradient(270deg, #00B39B, #3B71F7) !important\n}\n\n.animation {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.click_area {\n  position: absolute;\n  top: 10px;\n  left: 0;\n  height: calc(100% - 20px);\n  width: 100%;\n  cursor: pointer;\n\n  &--disabled {\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UeKFI1GNnkyZVpo7CNP5`,
	"button": `Fiv4wnz_AVZ1XjhO3zKp`,
	"animation": `v9J9TYRyIXKrH5wXpo6R`,
	"click_area": `zjhRQGPX0lw_OYDkMITz`,
	"click_area--disabled": `pKHEXHcq4aP7daxlj515`
};
export default ___CSS_LOADER_EXPORT___;
