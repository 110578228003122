import React, { FC, useEffect } from 'react';

import { ModalStateType, useModalContext } from '~/contexts/modal/ModalContext';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { colors } from '~/theme/colors';
import { ProductType } from '~/types/product';

import OrderDescription from './OrderDescription';
import PaymentForm from './PaymentForm';

type Props = {
  product: ProductType;
  onSuccessPayment: () => void | Promise<void>;
  userId: string;
  purchaseSource?: string;
};

export const multiScreenModalConfig: Partial<ModalStateType> = {
  isFullScreen: false,
  maxWidth: '460px',
  paddingSize: '0px',
  closeButtonColor: colors.neutral_30,
  pageViewMobile: true,
};

const UpgradeSubModalMultiScreen: FC<Props> = ({
  product,
  onSuccessPayment,
  purchaseSource,
}) => {
  const { id } = useAppSelector((state) => state.profile);
  const { handleOpenModal } = useModalContext();

  const handleCheckoutOpen = (): void => {
    analytics.trackEvent('subscription - upgrade modal click');

    handleOpenModal({
      ...multiScreenModalConfig,
      component: () => (
        <PaymentForm
          product={product}
          onSuccessPayment={onSuccessPayment}
          purchaseSource={purchaseSource}
        />
      ),
    });
  };

  useEffect(() => {
    analytics.trackEvent('platform checkout - view', {
      user_id: id,
      product_id: product.id,
      product_name: product.name,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  }, []);

  return (
    <OrderDescription offeredProduct={product} onClick={handleCheckoutOpen} />
  );
};

export default UpgradeSubModalMultiScreen;
