import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import SidebarItem from '~/components/atoms/SidebarItem';
import { SideBarItemType } from '~/core/sidebar';
import { checkIsRouteMatch } from '~/helpers/routeMatcher';

import styles from './styles.module.scss';

type Props = {
  items: SideBarItemType[][];
};

const SidebarDesktop: FC<Props> = ({ items }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.sidebar__items}>
        {items.map((items, i) => (
          <div key={i}>
            {items.map(
              ({ path, redirectPath, Icon, getTitle, getLabel, onClickCb }) => (
                <SidebarItem
                  isActive={checkIsRouteMatch(path, location.pathname)}
                  key={path}
                  Icon={Icon}
                  onClick={(): void => {
                    onClickCb && onClickCb();
                    navigate(redirectPath || path);
                  }}
                  title={getTitle(t)}
                  {...(getLabel ? { label: getLabel(t) } : {})}
                />
              ),
            )}
          </div>
        ))}
      </div>
    </aside>
  );
};

export default SidebarDesktop;
