import React, { FC, useEffect, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import Payment, { OrderShort } from '@solidgate/react-sdk';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import PaymentError from '~/components/molecules/PaymentError';
import PaymentButton from '~/components/organisms/PaymentForm/components/PaymentButton';
import {
  sliderFormParams,
  sliderFormStyles,
} from '~/components/organisms/PaymentForm/formConfig';
import {
  handleAlreadyProcessed,
  handlePaypalPayment,
  renderPaypalButton,
} from '~/components/organisms/PaymentForm/paypalMethods';
import useErrors from '~/hooks/useErrors';
import { Typographies } from '~/theme/typography';
import {
  AnalyticPaymentMethods,
  MerchantData,
  PAYMENT_METHODS,
  SOLID_PAYMENT_METHOD_MAPPER,
} from '~/types/payment';

import styles from './styles.module.scss';

type Props = {
  onPaymentSuccess: (order: OrderShort) => void | Promise<void>;
  onPaymentFail: (message: string, method: AnalyticPaymentMethods) => void;
  onCheckoutSubmit: (method: AnalyticPaymentMethods) => void;
  onPaymentMethodChange: (type: PAYMENT_METHODS) => void;
  onCreateCardIntentError?: (errorMessage: string) => void;
  onFormReady?: () => void | Promise<void>;
  paymentApi: {
    createPaymentIntentCard: (geo_country: string) => Promise<{
      merchant: string;
      payment_intent: string;
      signature: string;
    }>;
    createPaymentIntentPaypal: () => Promise<{
      order: Record<string, any>;
      order_metadata: any;
      pay_form: Record<string, any>;
    }>;
  };
};

const Checkout: FC<Props> = ({
  onPaymentSuccess,
  onPaymentFail,
  onCheckoutSubmit,
  onPaymentMethodChange,
  onCreateCardIntentError,
  onFormReady,
  paymentApi,
}) => {
  const { t } = useTranslation('general');
  const [activeCard, setActiveCard] = useState<PAYMENT_METHODS>(
    PAYMENT_METHODS.ONE_PAY,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [paypalScript, setPaypalScript] = useState('');
  const [merchantData, setMerchantData] = useState<MerchantData | null>(null);

  const paypalBtnRef = useRef<HTMLDivElement | null>(null);
  const appleContainerRef = useRef<HTMLDivElement | null>(null);
  const { reportUserErrors } = useErrors();

  const handlePaymentButtonClick = (type: PAYMENT_METHODS): void => {
    onPaymentMethodChange(type);
    setActiveCard(type);
  };

  const handleCloseError = (): void => {
    setPaymentError('');
  };

  const handleOnSuccess = async (order: OrderShort): Promise<void> => {
    await onPaymentSuccess(order);
    setIsLoading(false);
  };

  const handleOnFail = (
    message: string,
    method: AnalyticPaymentMethods,
  ): void => {
    onPaymentFail(message, method);

    setIsLoading(false);
    setMerchantData(null);
    setPaypalScript('');
    setPaymentError(message);
  };

  const handleOnSubmit = (method: AnalyticPaymentMethods): void => {
    onCheckoutSubmit(method);

    setIsLoading(true);
  };

  useEffect(() => {
    if (!merchantData) {
      (async (): Promise<void> => {
        try {
          const country = await Api.getCountry();
          const data = await paymentApi.createPaymentIntentCard(country);
          setMerchantData({ ...data, paymentIntent: data.payment_intent });
        } catch (e: any) {
          const errorMessage = e.error || e.message || 'Error :(';
          const isPaymentError = e.message?.includes('createPaymentIntentCard');
          reportUserErrors({
            error: e,
            method: 'createPaymentIntentCard',
            userMessage: isPaymentError
              ? t(
                  'error:create_payment_intent_card',
                  'Card payment initiation failed. Please re-enter your details or',
                )
              : errorMessage,
          });

          onCreateCardIntentError && onCreateCardIntentError(errorMessage);
        }
      })();
      return;
    }

    if (!paypalScript) {
      (async (): Promise<void> => {
        try {
          const paypalData = await paymentApi.createPaymentIntentPaypal();

          setPaypalScript(paypalData.pay_form.script_url);
          renderPaypalButton(paypalData.pay_form.script_url);
        } catch (e: any) {
          const isPaymentError = e.message.includes(
            'createPaymentIntentPaypal',
          );
          reportUserErrors({
            error: e,
            method: 'createPaymentIntentPaypal',
            userMessage: isPaymentError
              ? t(
                  'error:create_payment_intent_paypal',
                  'Paypal payment initiation unsuccessful. Try again or',
                )
              : e.error || e.message || 'Error :(',
          });
        }
      })();
    }
  }, [merchantData, paypalScript]);

  useEffect(() => {
    paypalBtnRef.current?.addEventListener('order-started-processing', () =>
      handleOnSubmit(PAYMENT_METHODS.PAYPAL),
    );

    paypalBtnRef.current?.addEventListener(
      'order-processed',
      handlePaypalPayment,
    );

    paypalBtnRef.current?.addEventListener(
      'order-already-processed',
      handleAlreadyProcessed,
    );

    return (): void => {
      paypalBtnRef.current?.removeEventListener(
        'order-started-processing',
        () => handleOnSubmit(PAYMENT_METHODS.PAYPAL),
      );

      paypalBtnRef.current?.removeEventListener(
        'order-processed',
        handlePaypalPayment,
      );

      paypalBtnRef.current?.removeEventListener(
        'order-already-processed',
        handleAlreadyProcessed,
      );
    };
  }, [paypalBtnRef.current]);

  useEffect(() => {
    const handlePaypalIframeEvent = async (
      mes: Record<string, any>,
    ): Promise<void> => {
      const { payment_status, method, error_message, order } = mes.data;

      if (payment_status === 'error') {
        await handleOnFail(error_message, method);
      }

      if (payment_status === 'info' || payment_status === 'success') {
        await handleOnSuccess(order);
      }
    };

    window.addEventListener('message', handlePaypalIframeEvent);

    return (): void => {
      window.removeEventListener('message', handlePaypalIframeEvent);
    };
  }, []);

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}

      {paymentError && (
        <PaymentError
          paymentError={paymentError}
          handleCloseError={handleCloseError}
        />
      )}

      {merchantData && !paymentError && (
        <Swiper
          slidesPerView={1}
          spaceBetween={16}
          initialSlide={0}
          centeredSlides
          onSwiper={(swiper): void => {
            activeCard === PAYMENT_METHODS.ONE_PAY && swiper.slideNext(); // By invoking this method we trigger slider for IOS Chrome
          }}
          className={clsx(styles.slider, {
            [styles.is_disabled]: isLoading,
          })}
        >
          <div className={styles.buttons}>
            {[PAYMENT_METHODS.ONE_PAY, PAYMENT_METHODS.CARD].map((type) => (
              <PaymentButton
                key={type}
                isActive={activeCard === type}
                onClick={handlePaymentButtonClick}
                type={type}
              />
            ))}
          </div>

          <SwiperSlide className={styles.slide}>
            <Typography
              className={styles.card_title}
              variant={Typographies.LABEL_LARGE}
              component="p"
            >
              {t('subscription:payment_form.card_details', 'Card details')}
            </Typography>

            <Payment
              applePayContainerRef={appleContainerRef}
              merchantData={merchantData}
              formParams={sliderFormParams(t)}
              styles={sliderFormStyles}
              onFail={(e): void =>
                handleOnFail(
                  e.message || '',
                  SOLID_PAYMENT_METHOD_MAPPER[e.entity],
                )
              }
              onSuccess={async (e): Promise<void> =>
                await handleOnSuccess(e.order)
              }
              onMounted={(): void => {
                window.setTimeout(() => {
                  onFormReady && onFormReady();
                }, 500);
              }}
              onSubmit={(e): void =>
                handleOnSubmit(SOLID_PAYMENT_METHOD_MAPPER[e.entity])
              }
            />
          </SwiperSlide>

          <SwiperSlide className={styles.slide}>
            <div
              className={styles['slide__pay_button']}
              ref={appleContainerRef}
            />
            <div
              className={styles['slide__pay_button']}
              id="paypal-button"
              ref={paypalBtnRef}
            />
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
};

export default Checkout;
