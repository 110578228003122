import { TFunction } from 'i18next';

import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import { PlagiarismExecutionType } from '~/modules/tools/views/variant2/PlagiarismCheckerV2/types';
import analytics from '~/services/analytics';

export enum DETECTION_STAGE {
  HIGH_LEVEL = 'high_level',
  PARTIAL_LEVEL = 'partial_level',
  HEALTHY_LEVEL = 'healthy_level',
  LOW_LEVEL = 'low_level',
}

export const PlagiarismCheckerTitle = 'Check Plagiarism';

export const DETECTION_EDGE: Record<DETECTION_STAGE, number> = {
  [DETECTION_STAGE.HIGH_LEVEL]: 21,
  [DETECTION_STAGE.PARTIAL_LEVEL]: 11,
  [DETECTION_STAGE.HEALTHY_LEVEL]: 1,
  [DETECTION_STAGE.LOW_LEVEL]: 0,
};

export const getLoaderSteps = (t: TFunction): string[] => [
  t('tools:plagiarism_checker.loader_steps[0]', 'Processing text'),
  t(
    'tools:plagiarism_checker.loader_steps[1]',
    'Searching for Identical Parts',
  ),
  t('tools:plagiarism_checker.loader_steps[2]', 'Searching for Changed Parts'),
  t(
    'tools:plagiarism_checker.loader_steps[3]',
    'Collecting Associated Sources',
  ),
];

export const EXAMPLE_RESPONSE: PlagiarismExecutionType = {
  scan_id: '82c0f243-c1bc-4f2a-8ef5-546e52ae7079',
  export_id: 'bb215137-2ea8-4d03-a6c3-c7315d5ac27d',
  aggregated_score: 100,
  identical_words: 100,
  minor_changed_words: 0,
  related_meaning_words: 0,
  omitted_words: 0,
  sources_count: 5,
  document_link:
    'https://api.justdone.ai/api/v1/plagiarism/pdf-report/bb215137-2ea8-4d03-a6c3-c7315d5ac27d',
  reaction: 0,
  request_message:
    "A gold standard is a monetary system in which the standard economic unit of account is based on a fixed quantity of gold. The gold standard was the basis for the international monetary system from the 1870s to the early 1920s, and from the late 1920s to 1932 as well as from 1944 until 1971 when the United States unilaterally terminated convertibility of the US dollar to gold, effectively ending the Bretton Woods system.Many states nonetheless hold substantial gold reserves.\n\nHistorically, the silver standard and bimetallism have been more common than the gold standard.The shift to an international monetary system based on a gold standard reflected accident, network externalities, and path dependence.Great Britain accidentally adopted a de facto gold standard in 1717 when Isaac Newton, then-master of the Royal Mint, set the exchange rate of silver to gold too low, thus causing silver coins to go out of circulation. As Great Britain became the world's leading financial and commercial power in the 19th century, other states increasingly adopted Britain's monetary system.\n\nThe gold standard was largely abandoned during the Great Depression before being reinstated in a limited form as part of the post-World War II Bretton Woods system. The gold standard was abandoned due to its propensity for volatility, as well as the constraints it imposed on governments: by retaining a fixed exchange rate, governments were hamstrung in engaging in expansionary policies to, for example, reduce unemployment during economic recessions.",
  is_contain_quotes: false,
  summary: 'Gold standard.',
  created_at: '2025-02-18T18:19:01.283396Z',
  updated_at: '2025-02-18T18:19:01.283396Z',
  demo_mode: true,
};

export const IsShowedPlagiarismTutorial = 'is_showed_plagiarism_tutorial';

export const getPlagiarismHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.PLAGIARISM_SIMPLIFIED_SCAN
  | HIGHLIGHT.PLAGIARISM_REMOVE_PLAGIARISM_BUTTON
  | HIGHLIGHT.PLAGIARISM_DETAILED_REPORT_BUTTON,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.PLAGIARISM_SIMPLIFIED_SCAN]: {
      title: t(
        'highlight:plagiarism.simplified_scan.title',
        'Exclude From Scanning',
      ),
      text: t(
        'highlight:plagiarism.simplified_scan.text',
        'The plagiarism checker will not flag these parts of texts as not unique',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: PlagiarismCheckerTitle,
          tip_number: 2,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: PlagiarismCheckerTitle,
          tip_number: 2,
        });
      },
    },
    [HIGHLIGHT.PLAGIARISM_REMOVE_PLAGIARISM_BUTTON]: {
      title: t(
        'highlight:plagiarism.remove_plagiarism.title',
        'Remove Plagiarism',
      ),
      text: t(
        'highlight:plagiarism.remove_plagiarism.text',
        'Use this feature to remove traces of plagiarism in your text to make it unique',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: PlagiarismCheckerTitle,
          tip_number: 3,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: PlagiarismCheckerTitle,
          tip_number: 3,
        });
      },
    },
    [HIGHLIGHT.PLAGIARISM_DETAILED_REPORT_BUTTON]: {
      title: t('highlight:plagiarism.detailed_report.title', 'Detailed Report'),
      text: t(
        'highlight:plagiarism.detailed_report.text',
        'Get a document with complete information about the text regarding plagiarism content inside',
      ),
      placement: 'bottom-start',
      buttonText: t('general:finish', 'Finish'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: PlagiarismCheckerTitle,
          tip_number: 4,
        });
      },
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [IsShowedPlagiarismTutorial]: true },
        });
        analytics.trackEvent('tutorial - finished', {
          tool: PlagiarismCheckerTitle,
        });
      },
    },
  };
};
