import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  platform: 'iPhone' | 'Android';
  steps: {
    img: {
      src: string;
      preview: string;
    };
    text: string;
  }[];
  onSubmit: () => void;
};

const MobileLayout: FC<Props> = ({ platform, steps, onSubmit }) => {
  const { t } = useTranslation('modal');

  return (
    <div className={styles.container}>
      <Typography
        variant={Typographies.TITLE_LARGE}
        className={styles.title}
        component="div"
      >
        <Trans i18nKey="pwa_v5.title" t={t}>
          Install JustDone App For {{ platform }}
        </Trans>
      </Typography>

      <div className={styles.steps}>
        {steps.map((step, idx) => {
          return (
            <div key={step.text} className={styles.steps__item}>
              <Typography
                variant={Typographies.LABEL_LARGE}
                className={styles.steps__item_title}
                component="div"
              >
                {idx + 1}. {step.text}
              </Typography>

              <div>
                <Image src={step.img.src} preview={step.img.preview} />
              </div>
            </div>
          );
        })}
      </div>

      <Button
        className={styles.button}
        color="primary"
        variant="contained"
        fullWidth
        onClick={onSubmit}
      >
        <span>{t('general:done', 'Done')}!</span>
      </Button>
    </div>
  );
};

export default MobileLayout;
