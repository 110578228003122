import React, { FC, useEffect, useState } from 'react';

import clsx from 'clsx';

import Loader from '~/components/atoms/Loader';
import { ModalStateType } from '~/contexts/modal/ModalContext';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { colors } from '~/theme/colors';
import { ProductType } from '~/types/product';

import OrderDescription from './OrderDescription';
import PaymentForm from './PaymentForm';
import styles from './styles.module.scss';

type Props = {
  product: ProductType;
  onSuccessPayment: () => void | Promise<void>;
  userId: string;
  purchaseSource?: string;
};

export const singleScreenModalConfig: Partial<ModalStateType> = {
  isFullScreen: false,
  maxWidth: '780px',
  paddingSize: '0px',
  closeButtonColor: colors.neutral_30,
  pageViewMobile: true,
};

const UpgradeSubModalSingleScreen: FC<Props> = ({
  product,
  onSuccessPayment,
  purchaseSource,
}) => {
  const { id } = useAppSelector((state) => state.profile);

  const [isFormShown, setIsFormShown] = useState(false);

  useEffect(() => {
    analytics.trackEvent('platform checkout - view', {
      user_id: id,
      product_id: product.id,
      product_name: product.name,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  }, []);

  return (
    <>
      {!isFormShown && <Loader />}

      <div
        className={clsx(styles.container, {
          [styles.is_shown]: isFormShown,
        })}
      >
        <div className={styles.description}>
          <OrderDescription offeredProduct={product} />
        </div>

        <div className={styles.checkout}>
          <PaymentForm
            product={product}
            onSuccessPayment={onSuccessPayment}
            onFormReady={() => setIsFormShown(true)}
            purchaseSource={purchaseSource}
          />
        </div>
      </div>
    </>
  );
};

export default UpgradeSubModalSingleScreen;
