import React, { FC } from 'react';

import { Helmet } from 'react-helmet';

const GTM_ID = 'GTM-5RDGHV3R'; // TODO move to env
const GTAG_ID = 'AW-11284279070';
const BING_ADS_ID = '97091049';

type Props = {
  children?: React.ReactNode;
};

const AuthAdditionalAnalyticsScripts: FC<Props> = ({ children }) => {
  return (
    <>
      {/*Google Tag Manager*/}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          // @ts-ignore
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      {/*End Google Tag Manager (noscript)*/}
      <Helmet>
        {/*Google Tag Manager*/}
        <script>
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
        </script>
        {/*End Google Tag Manager*/}

        {/*Google tag (gtag.js)*/}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id='${GTAG_ID}'"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          
          gtag('config', '${GTAG_ID}');`}
        </script>
        {/* End Google tag (gtag.js)*/}

        {/* Bing ads */}
        <script>{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:'${BING_ADS_ID}', enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</script>
        {/* End Bing ads */}
      </Helmet>
      {children}
    </>
  );
};
export default AuthAdditionalAnalyticsScripts;
