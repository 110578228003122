import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useModalContext } from '~/contexts/modal/ModalContext';
import { AiResearcherPath, QuizGeneratorPath } from '~/modules/tools/constants';
import { checkIsAiResearcherAccessible } from '~/modules/tools/views/AiResearcher/helpers/helpers';
import AiResearcherIntroModal from '~/modules/tools/views/AiResearcher/IntroModal';
import { checkIsQuizGeneratorAccessible } from '~/modules/tools/views/QuizGenerator/helpers';
import QuizGeneratorIntroModal from '~/modules/tools/views/QuizGenerator/IntroModal';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { capitalizeCaseToSnakeCase } from '~/utils/text';

type ModalConfig = {
  key: 'quiz generator' | 'ai researcher';
  condition: boolean;
  modalComponent: (
    onClose: () => void,
    updateFields: () => void,
  ) => JSX.Element;
};

type Props = {
  children?: React.ReactNode;
};

const tempData = {};

const NewToolsIntroWrapper: FC<Props> = ({ children }) => {
  const profile = useAppSelector((state) => state.profile);

  const { updateUserExtraFields } = useActions();
  const { handleOpenModal } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.isLoggedIn || !profile.is_onboarded) {
      return;
    }

    const modals: ModalConfig[] = [
      {
        key: 'quiz generator',
        condition:
          checkIsQuizGeneratorAccessible(profile) &&
          !(
            tempData['quiz_generator_intro_modal'] ||
            profile.extra_fields?.notifications?.quiz_generator_intro_modal
          ),
        modalComponent: (onClose, updateFields) => (
          <QuizGeneratorIntroModal
            onSubmit={() => {
              analytics.trackEvent('quiz generator - modal click');
              updateFields();
              onClose();
              window.setTimeout(() => navigate(QuizGeneratorPath), 1000);
            }}
            onRefuse={() => {
              analytics.trackEvent('quiz generator - modal dismiss');
              updateFields();
              onClose();
            }}
          />
        ),
      },
      {
        key: 'ai researcher',
        condition:
          checkIsAiResearcherAccessible(profile) &&
          Boolean(profile.extra_fields?.sign_up_at) &&
          Math.abs(
            DateService.differenceInDays(
              new Date(profile.extra_fields.sign_up_at || new Date().getTime()),
              new Date(),
            ),
          ) >= 1 &&
          !(
            tempData['ai_researcher_intro_modal'] ||
            profile.extra_fields?.notifications?.ai_researcher_intro_modal
          ),
        modalComponent: (onClose, updateFields) => (
          <AiResearcherIntroModal
            onSubmit={() => {
              analytics.trackEvent('ai researcher - modal click');
              updateFields();
              onClose();
              window.setTimeout(() => navigate(AiResearcherPath), 1000);
            }}
            onRefuse={() => {
              analytics.trackEvent('ai researcher - modal dismiss');
              updateFields();
              onClose();
            }}
          />
        ),
      },
    ];

    const modalToShow = modals.find((modal) => modal.condition);

    if (!modalToShow) {
      return;
    }

    const updateFields = (): void => {
      const key = `${capitalizeCaseToSnakeCase(modalToShow.key)}_intro_modal`;
      tempData[key] = 1;
      updateUserExtraFields({
        notifications: {
          ...profile.extra_fields?.notifications,
          [key]: 1,
        },
      });
    };

    handleOpenModal({
      disableBackdropClick: true,
      hideCloseButton: true,
      onShow: () => analytics.trackEvent(`${modalToShow.key} - modal show`),
      component: ({ onClose }) =>
        modalToShow.modalComponent(onClose, updateFields),
    });
  }, [profile]);

  return <>{children}</>;
};

export { NewToolsIntroWrapper };
