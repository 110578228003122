import { TFunction } from 'i18next';

import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import { REACTION } from '~/modules/reactions/types';
import { ExtendedAiHumanizerExecutionType } from '~/modules/tools/views/variant2/AiHumanizerV2/types';
import analytics from '~/services/analytics';

export const AiHumanizerTitle = 'AI Text Humanizer';

export enum HUMANIZER_MODE_V2 {
  Human = 'sound_human',
  Auto = 'auto',
  Detectors = 'bypass_detectors',
}

export const getHumanizerModeV2Options = (
  t: TFunction,
): Record<
  HUMANIZER_MODE_V2,
  {
    value: string;
    text: string;
  }
> => ({
  [HUMANIZER_MODE_V2.Human]: {
    value: 'sound_human',
    text: t('tools:ai_humanizer_v2.form_mode_options_text[0]', 'Sound Human'),
  },
  [HUMANIZER_MODE_V2.Auto]: {
    value: 'auto',
    text: t('tools:ai_humanizer_v2.form_mode_options_text[1]', 'Auto'),
  },
  [HUMANIZER_MODE_V2.Detectors]: {
    value: 'bypass_detectors',
    text: t(
      'tools:ai_humanizer_v2.form_mode_options_text[2]',
      'Bypass Detectors',
    ),
  },
});

export const CLICK_TOGGLER_ANALYTIC_NAMES: Record<HUMANIZER_MODE_V2, string> = {
  [HUMANIZER_MODE_V2.Auto]: 'ai humanizer - select auto goal',
  [HUMANIZER_MODE_V2.Human]: 'ai humanizer - select sound human goal',
  [HUMANIZER_MODE_V2.Detectors]: 'ai humanizer - select bypass detectors goal',
};

export const EXAMPLE_RESPONSE: ExtendedAiHumanizerExecutionType = {
  id: 'example_response',
  mode: 'Auto',
  language: 'English',
  reaction: REACTION.NONE,
  summary: 'Critique of the Dow Jones Industrial Average.',
  request_message:
    'I. Introduction to the Gold Standard\n' +
    '\n' +
    "The Gold Standard represents one of the most significant monetary systems in history, embodying a unique relationship between currency and precious metals. Under this system, the value of a country's currency is directly linked to a specific amount of gold, establishing a fixed exchange rate that creates stability in international trade. This framework not only facilitated cross-border transactions but also provided a sense of security for investors and consumers alike, as the intrinsic value of gold served as a reliable measure of wealth.\n" +
    '\n' +
    'Throughout history, the Gold Standard has been a point of contention, celebrated for its ability to promote economic stability while criticized for its inherent limitations. Its adoption marked a pivotal shift in global finance, influencing the economic policies of nations and reshaping the landscape of international commerce. As countries embraced this standard, they navigated a complex interplay of economic forces, often leading to profound implications for their financial systems.\n' +
    '\n' +
    "In this exploration of the Gold Standard, we will delve into its historical origins, examine its implementation across key nations, and analyze the economic repercussions it had on global trade. By understanding the Gold Standard's legacy, we can better appreciate its enduring significance in the context of modern monetary policy and economic theory. As we move forward, it is essential to recognize both its strengths and weaknesses, as these factors continue to inform discussions about currency systems today.",
  response_message:
    'I. The Gold Standard Explained\n' +
    '\n' +
    "The Gold Standard is one of the most important monetary systems in history, and it had a very unique relationship between the currency and precious metals. In this system, a country's currency has a basic value linked to a certain amount of gold, enabling a fixed exchange rate that ensures stability in international trade. It provided a bridge for cross-border transactions and a cushion for investors and consumers alike, as gold, after all, is a universal measure of wealth.\n" +
    '\n' +
    'The Gold Standard, historically, has been a double-edged sword, commended for fostering economic stability but derided for its restrictions. Its acceptance ushered in a significant change in international finance, shaping the policies of governments and transforming the landscape of world trade. Countries that adopted this standard grappled with a delicate balance of economic forces, often resulting in significant consequences for their financial systems.\n' +
    '\n' +
    'Join me here in this exploration of the Gold Standard as we look back at its birth, a look into how it was implemented in major nations, and the impact on world trade. After delving into the historical account of the Gold Standard, one gains a deeper understanding of its lasting impact on contemporary monetary policy and economic thought. With that being said, we must also take into account both the pros and cons, as elements of that type of system are still prevalent in conversations relating to modern-day currency systems as well.',

  created_at: '2025-02-13T14:05:15.303906Z',
  updated_at: '2025-02-13T14:05:18.0498Z',
  ai_edit_items: [],
  ai_detector: null,
  demo_mode: true,
};

export const IsShowedAiHumanizerTutorial = 'is_showed_ai_humanizer_tutorial';

export const getAiHumanizerHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.AI_HUMANIZER_GOAL_SOUND_HUMAN
  | HIGHLIGHT.AI_HUMANIZER_GOAL_AUTO
  | HIGHLIGHT.AI_HUMANIZER_GOAL_BYPASS_DETECTOR
  | HIGHLIGHT.AI_HUMANIZER_DETECT_AI
  | HIGHLIGHT.AI_HUMANIZER_AI_EDIT,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.AI_HUMANIZER_GOAL_SOUND_HUMAN]: {
      title: t('highlight:ai_humanizer.sound_human.title', 'Sound Human'),
      text: t(
        'highlight:ai_humanizer.sound_human.text',
        'Light humanization mode aimed to make text appear as human written but not necessarily pass the strictest detectors',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiHumanizerTitle,
          tip_number: 2,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiHumanizerTitle,
          tip_number: 2,
        });
      },
    },
    [HIGHLIGHT.AI_HUMANIZER_GOAL_AUTO]: {
      title: t('highlight:ai_humanizer.goal_auto.title', 'Auto'),
      text: t(
        'highlight:ai_humanizer.goal_auto.text',
        'The system detects the best mode for a text to achieve a balanced result',
      ),
      placement: 'bottom',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiHumanizerTitle,
          tip_number: 3,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiHumanizerTitle,
          tip_number: 3,
        });
      },
    },
    [HIGHLIGHT.AI_HUMANIZER_GOAL_BYPASS_DETECTOR]: {
      title: t(
        'highlight:ai_humanizer.bypass_detector.title',
        'Bypass Detectors',
      ),
      text: t(
        'highlight:ai_humanizer.bypass_detector.text',
        'Aggressive setting with the primary goal of bypassing the strictest detectors. Caution: sometimes, this may bring unusual results or grammar mistakes, so double-checking the text is recommended.',
      ),
      placement: 'bottom-end',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiHumanizerTitle,
          tip_number: 4,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiHumanizerTitle,
          tip_number: 4,
        });
      },
    },
    [HIGHLIGHT.AI_HUMANIZER_DETECT_AI]: {
      title: t('highlight:ai_humanizer.detect_ai.title', 'Detect AI'),
      text: t(
        'highlight:ai_humanizer.detect_ai.text',
        'Scan the text to double-check if it is being flagged as AI-generated',
      ),
      placement: 'top-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiHumanizerTitle,
          tip_number: 5,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiHumanizerTitle,
          tip_number: 5,
        });
      },
    },
    [HIGHLIGHT.AI_HUMANIZER_AI_EDIT]: {
      title: t('highlight:ai_humanizer.ai_edit.title', 'AI Edit'),
      text: t(
        'humanizer.ai_edit.text',
        'This feature allows you to ask AI to do different things with text, such as summarize, change tone, or do anything else you ask.',
      ),
      placement: 'top-start',
      buttonText: t('general:finish', 'Finish'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiHumanizerTitle,
          tip_number: 6,
        });
      },
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [IsShowedAiHumanizerTutorial]: true },
        });
        analytics.trackEvent('tutorial - finished', {
          tool: AiHumanizerTitle,
        });
      },
    },
  };
};
