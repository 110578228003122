import React from 'react';

import Api from '~/Api';
import PaymentForm, {
  paymentFormModalConfig,
} from '~/components/organisms/PaymentForm/views/PaymentForm';
import { PRODUCT } from '~/constants/products';
import { ModalStateType, useModalContext } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import UpgradeSubModalMultiScreen, {
  multiScreenModalConfig,
} from '~/modules/settings/components/modals/UpgradeSubscriptionModal/variants/MultiScreen';
import UpgradeSubModalSingleScreen, {
  singleScreenModalConfig,
} from '~/modules/settings/components/modals/UpgradeSubscriptionModal/variants/SingleScreen';
import { AB_TEST_FEATURES, AB_TESTS_KEYS } from '~/services/abTests/features';
import { growthBook } from '~/services/abTests/growthBook';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { ProductType } from '~/types/product';
import { timeout } from '~/utils/common';

const PURCHASE_SOURCE = 'sub_promo_button';

const featureVariants =
  AB_TEST_FEATURES[AB_TESTS_KEYS.ANNUAL_UPGRADE_IMPROVEMENT].variants;

const modalConfigMap: Record<
  string,
  {
    config: Partial<ModalStateType>;
    Component: React.FC<{
      product: ProductType;
      userId: string;
      purchaseSource?: string;
      onSuccessPayment: () => void | Promise<void>;
    }>;
  }
> = {
  [featureVariants.variant_1]: {
    config: singleScreenModalConfig,
    Component: UpgradeSubModalSingleScreen,
  },
  [featureVariants.variant_2]: {
    config: multiScreenModalConfig,
    Component: UpgradeSubModalMultiScreen,
  },
  [featureVariants.control]: {
    config: paymentFormModalConfig,
    Component: PaymentForm,
  },
};

export const useUpgradeSubscription = (): {
  upgradeSubscription: () => Promise<void>;
} => {
  const { loadAndSetActualProfileData } = useActions();
  const { reportUserErrors } = useErrors();
  const { handleOpenModal } = useModalContext();

  const { id } = useAppSelector((state) => state.profile);

  const abTestValue = growthBook.getAbTestValue(
    AB_TESTS_KEYS.ANNUAL_UPGRADE_IMPROVEMENT,
  );

  const upgradeSubscription = async (): Promise<void> => {
    try {
      const productById = await Api.getProductById(PRODUCT.ANNUAL_SUB_OFFER);

      analytics.trackEvent('subscription - change sub', {
        sub_id: productById.id,
      });

      const selectedModal =
        modalConfigMap[abTestValue] || modalConfigMap[featureVariants.control];

      const { config, Component } = selectedModal;

      handleOpenModal({
        onClose: () =>
          analytics.trackEvent('platform checkout - close', {
            purchase_source: PURCHASE_SOURCE,
          }),
        ...config,
        component: ({ onClose }) => (
          <Component
            product={productById}
            userId={id}
            purchaseSource={PURCHASE_SOURCE}
            onSuccessPayment={async () => {
              await timeout(4000);
              await loadAndSetActualProfileData();
              onClose();
            }}
          />
        ),
      });
    } catch (e: any) {
      reportUserErrors({
        error: e,
        method: 'getProductById',
        userMessage: 'Unable to fetch product details. Please retry or',
      });
    }
  };

  return {
    upgradeSubscription,
  };
};
