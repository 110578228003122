import { TFunction } from 'i18next';

import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import { REACTION } from '~/modules/reactions/types';
import {
  FactCheckerExecutionType,
  FactStatus,
} from '~/modules/tools/views/FactChecker/types';
import analytics from '~/services/analytics';

export const FactCheckerTitle = 'Fact Checker';

export const MAX_RESPONSE_LOADING_TIME = 15000;

export const SAMPLE_TEXT = `It is known that water freezes in a kettle when heated to +100 degrees Celsius. This phenomenon of water freezing was researched by Narnia National University.`;

export const EXAMPLE_RESPONSE: FactCheckerExecutionType = {
  id: 'example',
  reaction: REACTION.NONE,
  updated_text: '',
  is_copied: false,
  fact_checks: [
    {
      id: '2147aa9a-bca2-4a2d-a5b6-d001d709b809',
      original:
        'This phenomenon of water freezing was researched by Narnia National University',
      status: FactStatus.NONE,
      topic: 'Narnia National University',
      issue:
        'Narnia National University is fictional and does not conduct research.',
      corrected:
        'This phenomenon of water freezing was not researched by Narnia National University, which does not exist as a recognized institution',
      type: 'wrong',
      created_at: '2025-03-19T18:03:08.387694Z',
      updated_at: '2025-03-19T18:03:08.387694Z',
    },
  ],
  created_at: '2025-03-19T18:03:08.379385Z',
  updated_at: '2025-03-19T18:03:08.379385Z',
  demo_mode: true,
};

export const IsShowedFactCheckerTutorial = 'is_showed_fact_checker_tutorial';

export const getFactCheckerHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.FACT_CHECKER_TEXT_HIGHLIGHTS
  | HIGHLIGHT.FACT_CHECKER_ARROWS
  | HIGHLIGHT.FACT_CHECKER_RESPONSE
  | HIGHLIGHT.FACT_CHECKER_DISMISS_BUTTON
  | HIGHLIGHT.FACT_CHECKER_FIX_BUTTON,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.FACT_CHECKER_TEXT_HIGHLIGHTS]: {
      title: t(
        'highlight:fact_checker.text_highlights.title',
        'Highlighted Inaccuracies',
      ),
      text: t(
        'highlight:fact_checker.text_highlights.text',
        'Selected inaccuracy is highlighted in yellow, the rest in blue',
      ),
      placement: 'right-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: FactCheckerTitle,
          tip_number: 1,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: FactCheckerTitle,
          tip_number: 1,
        });
      },
    },
    [HIGHLIGHT.FACT_CHECKER_ARROWS]: {
      title: t('highlight:fact_checker.arrows.title', 'Navigate The Text'),
      text: t(
        'highlight:fact_checker.arrows.text',
        'These buttons allow you to highlight the next or previous sentence that contains inaccuracies',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: FactCheckerTitle,
          tip_number: 2,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: FactCheckerTitle,
          tip_number: 2,
        });
      },
    },
    [HIGHLIGHT.FACT_CHECKER_RESPONSE]: {
      title: t(
        'highlight:fact_checker.response.title',
        'Analysis Of Inaccuracy',
      ),
      text: t(
        'highlight:fact_checker.response.text',
        'Explanation of the issue found in the text accompanied by the provision of correct information',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: FactCheckerTitle,
          tip_number: 3,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: FactCheckerTitle,
          tip_number: 3,
        });
      },
    },
    [HIGHLIGHT.FACT_CHECKER_DISMISS_BUTTON]: {
      title: t('highlight:fact_checker.dismiss_button.title', 'Dismiss'),
      text: t(
        'highlight:fact_checker.dismiss_button.text',
        'Hit this button if you want to ignore the issue',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: FactCheckerTitle,
          tip_number: 4,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: FactCheckerTitle,
          tip_number: 4,
        });
      },
    },
    [HIGHLIGHT.FACT_CHECKER_FIX_BUTTON]: {
      title: t('highlight:fact_checker.fix_button.title', 'Fix The Issue'),
      text: t(
        'highlight:fact_checker.fix_button.text',
        'Hit this button to correct the inaccuracy according to the analysis',
      ),
      placement: 'bottom-start',
      buttonText: t('general:finish', 'Finish'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: FactCheckerTitle,
          tip_number: 5,
        });
      },
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [IsShowedFactCheckerTutorial]: true },
        });
        analytics.trackEvent('tutorial - finished', {
          tool: FactCheckerTitle,
        });
      },
    },
  };
};
