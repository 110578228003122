import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import PaymentForm, {
  paymentFormModalConfig,
} from '~/components/organisms/PaymentForm/views/PaymentForm';
import PaymentMethodUpdateForm from '~/components/organisms/PaymentForm/views/PaymentMethodUpdateForm';
import { PRODUCT } from '~/constants/products';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { useSnackBarContext } from '~/features/snackbar/SnackbarContext';
import useErrors from '~/hooks/useErrors';
import UnpauseModal from '~/modules/cancellation/features/pause/components/UnpauseModal';
import usePauseSubscription from '~/modules/cancellation/features/pause/hooks/usePauseSubscription';
import PaymentFixed from '~/modules/settings/components/modals/ChooseSubscriptionModal/views/PaymentFixed';
import RedemptFailedSub from '~/modules/settings/components/modals/ChooseSubscriptionModal/views/RedemptFailedSub';
import RedemptSub from '~/modules/settings/components/modals/ChooseSubscriptionModal/views/RedemptSub';
import { useRestoreSubscription } from '~/modules/settings/hooks/useRestoreSubscription';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { ProductType, REGULARITY } from '~/types/product';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';
import { timeout } from '~/utils/common';

import styles from './styles.module.scss';
import ProactiveUser from './views/ProactiveUser';
import RestoreSub from './views/RestoreSub';

export enum ReasonType {
  NO_ACTIVE_SUBSCRIPTION = 'not active sub',
  UNPAUSE_SUBSCRIPTION = 'paused sub',
  PROACTIVE_USER = 'sub promo',
  REDEMPTION = 'redemption',
  REDEMPTION_FAILED = 'redemption_failed',
}

const getHandleError =
  (t: TFunction) =>
  (e: any): { error: any; method: string; userMessage: string } => {
    const isSubError = e.message.includes('getSubscription');
    const isListError = e.message.includes('getProductList');

    return {
      error: e,
      method: isSubError
        ? 'getSubscription'
        : isListError
          ? 'getProductList'
          : 'getProductById',
      userMessage: isSubError
        ? t('get_subscription', {
            ns: 'error',
            defaultValue:
              'Error fetching subscription details. Refresh or try later or',
          })
        : isListError
          ? t('get_product_list', {
              ns: 'error',
              defaultValue: 'Failed to load product list. Please try again or',
            })
          : t('get_product_by_id', {
              ns: 'error',
              defaultValue: 'Unable to fetch product details. Please retry or',
            }),
    };
  };

type Props = {
  onClose: () => void;
  type: ReasonType;
};

const ChooseSubscriptionModal: FC<Props> = ({ type, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [offeredProduct, setOfferedProduct] = useState<ProductType | null>(
    null,
  );
  const [products, setProducts] = useState<ProductType[]>([]);

  const { t } = useTranslation('modal');
  const { showSnackbar } = useSnackBarContext();
  const { handleUnpauseSub } = usePauseSubscription();

  const { handleOpenModal } = useModalContext();
  const { loadAndSetActualProfileData, updateProfileSubscriptionStatus } =
    useActions();
  const { reportUserErrors, reportProfileError } = useErrors();
  const { restoreSubscription, restoreSubscriptionHard } =
    useRestoreSubscription();

  const profile = useAppSelector((state) => state.profile);

  const handleError = getHandleError(t);

  const loadDataWithDelay = async (): Promise<void> => {
    try {
      await timeout(4000);
      await loadAndSetActualProfileData();
      onClose();
    } catch (e: any) {
      reportProfileError(e);
    }
  };

  const handleOpenPaymentForm = (product: ProductType | null): void => {
    if (product) {
      analytics.trackEvent('subscription - change sub', {
        sub_id: product.id,
      });

      handleOpenModal({
        onClose: () => {
          analytics.trackEvent('platform checkout - close');
        },
        ...paymentFormModalConfig,
        component: ({ onClose }) => (
          <PaymentForm
            userId={profile.id}
            product={product}
            onSuccessPayment={async (): Promise<void> => {
              await loadDataWithDelay();
              onClose();
            }}
          />
        ),
      });
    }
  };

  const handleRestoreSubscription = async (): Promise<void> => {
    analytics.trackEvent('subscription - click not active sub screen', {
      sub: profile.user_subscription.subscription_name,
    });

    try {
      setIsLoading(true);
      await restoreSubscription();
    } catch (e: any) {
      reportUserErrors({
        error: e,
        method: 'restoreSubscription',
        userMessage: t(
          'error:unable_restore_subscription',
          'Unable to restore subscription. Please contact support or',
        ),
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleRedemptionFailed = async (): Promise<void> => {
    handleOpenModal({
      onClose: () => {
        analytics.trackEvent('platform checkout - close payment update');
      },
      disableBackdropClick: true,
      maxWidth: '800px',
      component: () => (
        <PaymentMethodUpdateForm
          onSuccessPayment={async (): Promise<void> => {
            updateProfileSubscriptionStatus(SUBSCRIPTION_STATUS.ACTIVE);
            showSnackbar(
              t('payment_issues_fixed', {
                ns: 'notification',
                defaultValue: 'Payment Issues Fixed!',
              }),
              'success',
              5000,
            );
            handleOpenModal({
              hideCloseButton: true,
              disableBackdropClick: true,
              maxWidth: '460px',
              component: ({ onClose }) => <PaymentFixed onClose={onClose} />,
            });
          }}
        />
      ),
    });
  };

  const handleViewPlansClick = (): void => {
    analytics.trackEvent('subscription - view all plans');
  };

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setIsLoading(true);
        const [productById, products] = await Promise.all([
          Api.getProductById(PRODUCT.ANNUAL_SUB_OFFER),
          Api.getProductList(),
        ]);

        const filteredProducts = products?.filter(
          (product) =>
            product.id !== profile.user_subscription.product_id &&
            product.regularity !== REGULARITY.ONETIME &&
            !product.has_trial_period,
        );

        setOfferedProduct(productById);
        setProducts(filteredProducts);
      } catch (e: any) {
        reportUserErrors(handleError(e));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const restoredProduct: ProductType = {
    ...profile.user_product,
    name: profile.user_subscription.subscription_name,
    is_popular_ui: false,
    old_price_ui: 0,
    discount_percentage_ui: 0,
  };

  const SCREENS: Record<ReasonType, ReactElement<any, any>> | undefined =
    useMemo(() => {
      if (!offeredProduct) {
        return;
      }

      return {
        [ReasonType.PROACTIVE_USER]: (
          <ProactiveUser
            newProduct={offeredProduct}
            onSubmit={(): void => handleOpenPaymentForm(offeredProduct)}
            {...(products?.length > 0 && {
              onViewPlansClick: handleViewPlansClick,
            })}
          />
        ),
        [ReasonType.UNPAUSE_SUBSCRIPTION]: (
          <UnpauseModal onSubmit={handleUnpauseSub} onRefuse={onClose} />
        ),
        [ReasonType.NO_ACTIVE_SUBSCRIPTION]: (
          <RestoreSub
            restoredProduct={restoredProduct}
            {...(products?.length > 0 && {
              onViewPlansClick: handleViewPlansClick,
            })}
            onSubmit={handleRestoreSubscription}
          />
        ),
        [ReasonType.REDEMPTION]: (
          <RedemptSub onClose={onClose} onSubmit={restoreSubscriptionHard} />
        ),
        [ReasonType.REDEMPTION_FAILED]: (
          <RedemptFailedSub
            onClose={onClose}
            onSubmit={handleRedemptionFailed}
          />
        ),
      };
    }, [offeredProduct]);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />

      {SCREENS && SCREENS[type]}
    </div>
  );
};

export default ChooseSubscriptionModal;
