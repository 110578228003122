import React from 'react';

import { TFunction } from 'i18next';

import AndroidChromeAddressImg from '~/features/pwa/assets/android_chrome_address_bar.webp';
import AndroidChromeAddressPreviewImg from '~/features/pwa/assets/android_chrome_address_bar_preview.png';
import AndroidInstallAppImg from '~/features/pwa/assets/android_install_app.webp';
import AndroidInstallAppPreviewImg from '~/features/pwa/assets/android_install_app_preview.png';
import IosAddButtonImg from '~/features/pwa/assets/ios_add_button.webp';
import IosAddButtonPreviewImg from '~/features/pwa/assets/ios_add_button_preview.png';
import IosChromeAddressImg from '~/features/pwa/assets/ios_chrome_address_bar.webp';
import IosChromeAddressPreviewImg from '~/features/pwa/assets/ios_chrome_address_bar_preview.png';
import IosSafariAddressImg from '~/features/pwa/assets/ios_safari_address_bar.webp';
import IosSafariAddressPreviewImg from '~/features/pwa/assets/ios_safari_address_bar_preview.png';
import MacHomeScreenImg from '~/features/pwa/assets/mac_home_screen.webp';
import MacHomeScreenPreviewImg from '~/features/pwa/assets/mac_home_screen_modal.jpg';
import MacSafariAddressImg from '~/features/pwa/assets/mac_safari_address_bar_v2.webp';
import MacSafariAddressPreviewImg from '~/features/pwa/assets/mac_safari_address_bar_v2_preview.png';
import WindowsHomeScreenImg from '~/features/pwa/assets/windows_home_screen.webp';
import WindowsHomeScreenPreviewImg from '~/features/pwa/assets/windows_home_screen_modal.jpg';
import WindowsMacChromeAddressImg from '~/features/pwa/assets/windows_mac_chrome_address_bar.webp';
import WindowsMacChromeAddressPreviewImg from '~/features/pwa/assets/windows_mac_chrome_address_bar_preview.png';
import WindowsMacEdgeAddressImg from '~/features/pwa/assets/windows_mac_edge_address_bar.webp';
import WindowsMacEdgeAddressPreviewImg from '~/features/pwa/assets/windows_mac_edge_address_bar_preview.png';
import { PWADevice } from '~/features/pwa/constants';
import DesktopLayout from '~/features/pwa/versions/V5/layouts/DesktopLayout';
import MobileLayout from '~/features/pwa/versions/V5/layouts/MobileLayout';

type ConfigComponentProps = {
  t: TFunction;
  onSubmit: () => void;
};

const deviceComponents: Record<
  PWADevice,
  (props: ConfigComponentProps) => JSX.Element
> = {
  [PWADevice.Ios_Safari]: ({ t, onSubmit }) => (
    <MobileLayout
      platform="iPhone"
      steps={[
        {
          img: {
            src: IosSafariAddressImg,
            preview: IosSafariAddressPreviewImg,
          },
          text: t(
            'modal:pwa_v4.safari_mobile_steps[0]',
            'Tap this button on the bottom of Safari',
          ),
        },
        {
          img: {
            src: IosAddButtonImg,
            preview: IosAddButtonPreviewImg,
          },
          text: t(
            'modal:pwa_v4.safari_mobile_steps[1]',
            'In window that appears tap this',
          ),
        },
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Ios_Chrome]: ({ t, onSubmit }) => (
    <MobileLayout
      platform="iPhone"
      steps={[
        {
          img: {
            src: IosChromeAddressImg,
            preview: IosChromeAddressPreviewImg,
          },
          text: t(
            'modal:pwa_v4.chrome_mobile_ios_steps[0]',
            'Tap this button in Chrome address bar',
          ),
        },
        {
          img: {
            src: IosAddButtonImg,
            preview: IosAddButtonPreviewImg,
          },
          text: t(
            'modal:pwa_v4.chrome_mobile_ios_steps[1]',
            'In window that appears tap this',
          ),
        },
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Android_Chrome]: ({ t, onSubmit }) => (
    <MobileLayout
      platform="Android"
      steps={[
        {
          img: {
            src: AndroidChromeAddressImg,
            preview: AndroidChromeAddressPreviewImg,
          },
          text: t(
            'modal:pwa_v4.chrome_mobile_android_steps[0]',
            'Tap this button on the top of Chrome',
          ),
        },
        {
          img: {
            src: AndroidInstallAppImg,
            preview: AndroidInstallAppPreviewImg,
          },
          text: t(
            'modal:pwa_v4.chrome_mobile_android_steps[1]',
            'In window that appears tap this',
          ),
        },
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Mac_Chrome]: ({ t, onSubmit }) => (
    <DesktopLayout
      platform="Mac"
      bcgImg={{ src: MacHomeScreenImg, preview: MacHomeScreenPreviewImg }}
      stepsImg={{
        src: WindowsMacChromeAddressImg,
        preview: WindowsMacChromeAddressPreviewImg,
      }}
      steps={[
        t(
          'modal:pwa_v4.chrome_desktop_steps[0]',
          'Click this icon in the address bar at the top',
        ),
        t('modal:pwa_v4.chrome_desktop_steps[1]', 'Click “Install”'),
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Mac_Edge]: ({ t, onSubmit }) => (
    <DesktopLayout
      platform="Mac"
      bcgImg={{ src: MacHomeScreenImg, preview: MacHomeScreenPreviewImg }}
      stepsImg={{
        src: WindowsMacEdgeAddressImg,
        preview: WindowsMacEdgeAddressPreviewImg,
      }}
      steps={[
        t(
          'modal:pwa_v4.chrome_desktop_steps[0]',
          'Click this icon in the address bar at the top',
        ),
        t('modal:pwa_v4.chrome_desktop_steps[1]', 'Click “Install”'),
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Mac_Safari]: ({ t, onSubmit }) => (
    <DesktopLayout
      platform="Mac"
      bcgImg={{ src: MacHomeScreenImg, preview: MacHomeScreenPreviewImg }}
      stepsImg={{
        src: MacSafariAddressImg,
        preview: MacSafariAddressPreviewImg,
      }}
      steps={[
        t(
          'modal:pwa_v4.safari_desktop_steps[0]',
          'Hit such icon in top right of your screen',
        ),
        t(
          'modal:pwa_v5.safari_desktop_steps[1]',
          'Choose “Add to Dock” option',
        ),
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Windows_Chrome]: ({ t, onSubmit }) => (
    <DesktopLayout
      platform="Windows"
      bcgImg={{
        src: WindowsHomeScreenImg,
        preview: WindowsHomeScreenPreviewImg,
      }}
      stepsImg={{
        src: WindowsMacChromeAddressImg,
        preview: WindowsMacChromeAddressPreviewImg,
      }}
      steps={[
        t(
          'modal:pwa_v4.chrome_desktop_steps[0]',
          'Click this icon in the address bar at the top',
        ),
        t('modal:pwa_v4.chrome_desktop_steps[1]', 'Click “Install”'),
      ]}
      onSubmit={onSubmit}
    />
  ),

  [PWADevice.Windows_Edge]: ({ t, onSubmit }) => (
    <DesktopLayout
      platform="Mac"
      bcgImg={{
        src: WindowsHomeScreenImg,
        preview: WindowsHomeScreenPreviewImg,
      }}
      stepsImg={{
        src: WindowsMacEdgeAddressImg,
        preview: WindowsMacEdgeAddressPreviewImg,
      }}
      steps={[
        t(
          'modal:pwa_v4.chrome_desktop_steps[0]',
          'Click this icon in the address bar at the top',
        ),
        t('modal:pwa_v4.chrome_desktop_steps[1]', 'Click “Install”'),
      ]}
      onSubmit={onSubmit}
    />
  ),
};

export const getConfigComponent = (
  device: PWADevice,
): ((props: ConfigComponentProps) => JSX.Element) | null => {
  const Component = deviceComponents[device];

  return Component
    ? ({ t, onSubmit }: ConfigComponentProps): JSX.Element => (
        <Component t={t} onSubmit={onSubmit} />
      )
    : null;
};
