import { REACTION } from '~/modules/reactions/types';

export enum QuestionType {
  SINGLE = 'single_choice',
  MULTI = 'multiple_choice',
}

export type OptionType = {
  id: string;
  text: string;
  is_correct: boolean;
  is_selected: boolean;
};

export type QuestionItemType = {
  id: string;
  type: QuestionType;
  question: string;
  options: Array<OptionType>;
  is_completed: boolean;
};

export type ExtendedOptionType = OptionType & { letter: string };

export type ProcessedQuizOptions = {
  userAnswers: ExtendedOptionType[];
  correctAnswers: ExtendedOptionType[];
  selectedIncorrect: boolean;
  selectedCorrect: boolean;
};

export type QuizGeneratorExecutionType = {
  execution_id: string;
  title: string;
  quiz: QuestionItemType[];
  reaction: REACTION;
};
