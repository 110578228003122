import { ONBOARDING_CARDS } from '~/modules/onboarding-new/constants';
import {
  ProfileAction,
  ProfileActionTypes,
  ProfileState,
} from '~/store/types/profile';
import { ProductType } from '~/types/product';
import { SubscriptionType } from '~/types/subscription';

const initialState: ProfileState = {
  id: '',
  isLoggedIn: false,
  email: '',
  name: '',
  avatar: '',
  words_amount_left: 0,
  response_count_total: 0,
  is_stripe_user: false,
  is_onboarded: true,
  is_chrome_extension_installed: false,
  created_at: '',
  updated_at: '',
  extra_fields: {
    notifications: {},
    ab_tests: {},
    highlights: {},
    test_form: {},
    sign_up_at: null,
    cancel_sub_reasons: {},
    rewards: {},
    feedbackV3: {},
    is_contributor: false,
    pwa_modal: {
      last_showed_at: null,
      is_showed_first_generation: false,
      is_showed_fifth_session: false,
    },
    trust_pilot_can_show_after: null,
    platform_lang: null,
  },
  user_product: {} as ProductType,
  user_subscription: {} as SubscriptionType,
  user_onboarding: {} as Record<ONBOARDING_CARDS, string>,
  backend_ab_tests: {},
  user_features: {},
  interaction_data_count: {
    response: {
      '-1': 0,
      '1': 0,
    },
    rate: {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
    },
  },
};

export const profileReducer = (
  state = initialState,
  action: ProfileAction,
): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.UPDATE_PROFILE:
      return { ...state, ...action.payload };
    case ProfileActionTypes.SIGN_IN:
      return { ...state, isLoggedIn: true };
    case ProfileActionTypes.SIGN_OUT:
      return { ...state, isLoggedIn: false };
    case ProfileActionTypes.INCREASE_RESPONSE_COUNT:
      return { ...state, response_count_total: state.response_count_total + 1 };
    default:
      return state;
  }
};
