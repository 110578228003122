import { TFunction } from 'i18next';

import { AdvancedLoaderProps } from '~/components/organisms/AdvancedLoader';
import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import { FORM_FIELDS } from '~/modules/tools/types';
import { Step } from '~/modules/tools/views/AdvancedArticleGenerator/Stepper';
import analytics from '~/services/analytics';

import { SCREEN } from './types';

export const getSteps = (t: TFunction): Step[] => [
  {
    step: SCREEN.TOPIC,
    title: t('general:set_topic', 'Set Topic'),
    highLightKey: HIGHLIGHT.AAG_ONBOARDING_STEP_1,
  },
  {
    step: SCREEN.OUTlINE,
    title: t('general:confirm_outline', 'Confirm Outline'),
    highLightKey: HIGHLIGHT.AAG_ONBOARDING_STEP_2,
  },
  {
    step: SCREEN.ARTICLE,
    title: t('general:read_article', 'Read Article'),
    highLightKey: HIGHLIGHT.AAG_ONBOARDING_STEP_3,
  },
];

export const getLoader = (
  t: TFunction,
): Record<'outline' | 'article', AdvancedLoaderProps> => ({
  outline: {
    title: t('aag.loader_outline_title', {
      ns: 'tools',
      defaultValue: 'We’re generating your outline...',
    }),
    steps: [
      {
        key: '1',
        name: t('aag.loader_outline_steps[0]', {
          ns: 'tools',
          defaultValue: 'Generating Skeleton',
        }),
      },
      {
        key: '2',
        name: t('aag.loader_outline_steps[1]', {
          ns: 'tools',
          defaultValue: 'Revising Topics',
        }),
      },
      {
        key: '3',
        name: t('aag.loader_outline_steps[2]', {
          ns: 'tools',
          defaultValue: 'Optimizing Structure',
        }),
      },
      {
        key: '4',
        name: t('aag.loader_outline_steps[3]', {
          ns: 'tools',
          defaultValue: 'Finalizing Outline...',
        }),
      },
    ],
    switchTimeout: 2500, // 2,5 sec
    estimatedTime: 12000, // 12 sec
  },
  article: {
    title: t('aag.loader_article_title', {
      ns: 'tools',
      defaultValue: 'We’re generating your article...',
    }),
    steps: [
      {
        key: '1',
        name: t('aag.loader_article_steps[0]', {
          ns: 'tools',
          defaultValue: 'Processing outline',
        }),
      },
      {
        key: '2',
        name: t('aag.loader_article_steps[1]', {
          ns: 'tools',
          defaultValue: 'Generating article',
        }),
      },
      {
        key: '3',
        name: t('aag.loader_article_steps[2]', {
          ns: 'tools',
          defaultValue: 'Synthesizing article',
        }),
      },
      {
        key: '4',
        name: t('aag.loader_article_steps[3]', {
          ns: 'tools',
          defaultValue: 'Improving article...',
        }),
      },
    ],
    switchTimeout: 25000, // 25 sec
    estimatedTime: 120000, // 2 min
  },
});

export const getAagToolFormConfig = (t: TFunction): Record<string, any> => ({
  ui_schema: {
    topic: {
      title: t('general:topic', 'Topic'),
      type: FORM_FIELDS.TEXT,
      props: {
        required: true,
        placeholder: t(
          'tools:aag.topic_input_placeholder',
          'Enter the title or topic of article here',
        ),
      },
    },
  },
  validation_schema: {
    $schema: 'http://json-schema.org/draft-04/schema#',
    type: 'object',
    properties: {
      topic: {
        type: 'string',
        maxLength: 200,
        minLength: 1,
        pattern: '\\S',
        required: true,
      },
    },
    required: ['topic'],
  },
});

export const IsShowedHighlightAAGKey = 'is_showed_highlight_aag';

export const getAAGHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.AAG_ONBOARDING_STEP_1
  | HIGHLIGHT.AAG_ONBOARDING_STEP_2
  | HIGHLIGHT.AAG_ONBOARDING_STEP_3,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.AAG_ONBOARDING_STEP_1]: {
      title: t('highlight:aag_onboarding_step1.title', 'Topic'),
      text: t(
        'highlight:aag_onboarding_step1.text',
        'Enter your desired topic to generate an article.',
      ),
      buttonText: t('general:next', 'Next'),
      subTitle: t('highlight:aag_onboarding_step1.subtitle', 'Step 1 of 3'),
      onSubmitCallback: (): void => {
        //analytics here
      },
    },
    [HIGHLIGHT.AAG_ONBOARDING_STEP_2]: {
      title: t('general:outline', 'Outline'),
      text: t(
        'highlight:aag_onboarding_step2.text',
        'Generating an article involves creating an initial outline, which will serve as the basis for the content.',
      ),
      buttonText: t('general:next', 'Next'),
      subTitle: t('highlight:aag_onboarding_step2.subtitle', 'Step 2 of 3'),
      onSubmitCallback: (): void => {
        //analytics here
      },
    },
    [HIGHLIGHT.AAG_ONBOARDING_STEP_3]: {
      title: t('highlight:aag_onboarding_step3.title', 'Article'),
      text: t(
        'highlight:aag_onboarding_step3.text',
        'Review the generated article and download it in your preferred format.',
      ),
      buttonText: t('general:finish', 'Finish'),
      subTitle: t('highlight:aag_onboarding_step3.subtitle', 'Step 3 of 3'),
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [IsShowedHighlightAAGKey]: true },
        });
        analytics.trackEvent('tool aag - finish steps onboarding');
      },
    },
  };
};
