import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { PWADevice } from '~/features/pwa/constants';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';

import { getConfigComponent } from '../config';

type Props = {
  device: PWADevice;
  onSubmit: () => void;
};

const AddToHomeModalV5: FC<Props> = ({ device, onSubmit }) => {
  const Component = getConfigComponent(device);

  if (!Component) {
    return null;
  }

  const { t } = useTranslation('modal');
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  return (
    <div style={{ padding: isDesktop ? '0' : '24px 12px 12px' }}>
      <Component t={t} onSubmit={onSubmit} />
    </div>
  );
};

export default AddToHomeModalV5;
