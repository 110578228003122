import React, { FC } from 'react';

import Rive, { Fit, Layout } from '@rive-app/react-canvas';
import clsx from 'clsx';

import Button from '~/components/atoms/buttons/Button';

import animationSrc from './sparkles.riv';
import styles from './styles.module.scss';

type Props = {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
};

const StarsAnimatedButton: FC<Props> = ({ text, onClick, isDisabled }) => {
  return (
    <div className={styles.container}>
      <Button
        fullWidth
        variant="contained"
        size="large"
        disabled={isDisabled}
        className={styles.button}
        onClick={onClick}
      >
        {text}
      </Button>
      <Rive
        className={styles.animation}
        layout={
          new Layout({
            fit: Fit.Cover,
          })
        }
        shouldResizeCanvasToContainer
        src={animationSrc}
      />
      <div
        onClick={onClick}
        className={clsx(styles.click_area, {
          [styles['click_area--disabled']]: isDisabled,
        })}
      />
    </div>
  );
};

export default StarsAnimatedButton;
