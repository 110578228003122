import React, { FC, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import Checkout from '~/components/organisms/Checkout';
import Agreement from '~/components/organisms/PaymentForm/components/Agreement';
import Guarantee from '~/components/organisms/PaymentForm/components/Guarantee';
import PaymentInfo from '~/components/organisms/PaymentForm/components/PaymentInfo';
import PaymentSystems from '~/components/organisms/PaymentForm/components/PaymentSystems';
import UpdatePaymentDescription from '~/components/organisms/PaymentForm/components/UpdatePaymentDescription';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { AnalyticPaymentMethods, PAYMENT_METHODS } from '~/types/payment';

import styles from './styles.module.scss';

type Props = {
  onSuccessPayment: () => void | Promise<void>;
};

const PaymentMethodUpdateForm: FC<Props> = ({ onSuccessPayment }) => {
  const { t } = useTranslation('general');

  const [isFormShown, setIsFormShown] = useState(false);

  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handlePaymentSuccess = async (): Promise<void> => {
    analytics.trackEvent('platform checkout - payment update success');
    await onSuccessPayment();
  };

  const handlePaymentFail = (
    message: string,
    method: AnalyticPaymentMethods,
  ): void => {
    analytics.trackEvent('platform checkout - error', {
      method,
      error: message,
    });
  };

  const handleCheckoutSubmit = (method: AnalyticPaymentMethods): void => {
    analytics.trackEvent('platform checkout - submit', {
      method,
    });
  };

  const handlePaymentMethodChange = (type: PAYMENT_METHODS): void => {
    analytics.trackEvent('platform checkout - navigation', {
      method: type,
    });
  };

  useEffect(() => {
    analytics.trackEvent('platform checkout - payment update view');
  }, []);

  return (
    <>
      {!isFormShown && <Loader />}

      <div className={styles.outer_wrapper}>
        <div
          className={clsx(styles.inner_wrapper, {
            [styles.is_shown]: isFormShown,
          })}
        >
          {isDesktop && (
            <div className={styles.description}>
              <UpdatePaymentDescription />
            </div>
          )}

          <div className={styles.form_outer}>
            <div className={styles.form_inner}>
              <Typography
                className={styles.title}
                variant={Typographies.LABEL_LARGE}
                component="h3"
              >
                {t(
                  'subscription:payment_form.select_payment_method',
                  'Select your payment method',
                )}
              </Typography>

              <Checkout
                onPaymentSuccess={handlePaymentSuccess}
                onPaymentFail={handlePaymentFail}
                onCheckoutSubmit={handleCheckoutSubmit}
                onPaymentMethodChange={handlePaymentMethodChange}
                onFormReady={() => setIsFormShown(true)}
                paymentApi={{
                  createPaymentIntentCard: (geo_country) =>
                    Api.updatePaymentMethodCard(geo_country),
                  createPaymentIntentPaypal: () =>
                    Api.updatePaymentMethodPaypal(),
                }}
              />

              <Guarantee />
              <PaymentInfo
                id=""
                price="0"
                title={t(
                  'subscription:payment_form.payment_method_update',
                  'Payment Method Update',
                )}
                description={t(
                  'subscription:payment_form.payment_method_update_desc',
                  'Updated method will be used for billing your existing plan',
                )}
              />
              <PaymentSystems />
            </div>
            <Agreement />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodUpdateForm;
