import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import { OrderShort } from '@solidgate/react-sdk';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Api from '~/Api';
import Loader from '~/components/atoms/Loader';
import Checkout from '~/components/organisms/Checkout';
import SecuredSystemsV2Img from '~/components/organisms/PaymentForm/assets/secured_systems_v2.webp';
import { getProductName } from '~/helpers/translation';
import { ANNUAL_PLAN_OLD_PRICE } from '~/modules/settings/components/modals/UpgradeSubscriptionModal/variants/constants';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';
import { AnalyticPaymentMethods, PAYMENT_METHODS } from '~/types/payment';
import { ProductType } from '~/types/product';

import styles from './styles.module.scss';

type Props = {
  product: ProductType;
  onSuccessPayment: () => void | Promise<void>;
  purchaseSource?: string;
};

const PaymentForm: FC<Props> = ({
  product,
  onSuccessPayment,
  purchaseSource,
}) => {
  const { id } = useAppSelector((state) => state.profile);

  const [isFormShown, setIsFormShown] = useState(false);

  const { t } = useTranslation('general');

  const handlePaymentSuccess = async (order: OrderShort): Promise<void> => {
    analytics.trackPurchase('platform payment - success', {
      user_id: id,
      value: order.amount ? order.amount / 100 : 0,
      product_id: product.id,
      product_name: product.name,
      order_id: order.order_id ? order.order_id : '',
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });

    await onSuccessPayment();
  };

  const handlePaymentFail = (
    message: string,
    method: AnalyticPaymentMethods,
  ): void => {
    analytics.trackEvent('platform checkout - error', {
      method,
      error: message,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  const handleCheckoutSubmit = (method: AnalyticPaymentMethods): void => {
    analytics.trackEvent('platform checkout - submit', {
      method,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  const handlePaymentMethodChange = (type: PAYMENT_METHODS): void => {
    analytics.trackEvent('platform checkout - navigation', {
      method: type,
      ...(purchaseSource ? { purchase_source: purchaseSource } : {}),
    });
  };

  return (
    <div className={styles.container}>
      {!isFormShown && <Loader />}

      <div
        className={clsx(styles.form, {
          [styles.is_shown]: isFormShown,
        })}
      >
        <Typography
          className={styles.title}
          variant={Typographies.TITLE_MEDIUM}
          component="h3"
        >
          {t(
            'subscription:payment_form.select_payment_method',
            'Select your payment method',
          )}
        </Typography>

        <Checkout
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentFail={handlePaymentFail}
          onCheckoutSubmit={handleCheckoutSubmit}
          onPaymentMethodChange={handlePaymentMethodChange}
          onFormReady={() => setIsFormShown(true)}
          paymentApi={{
            createPaymentIntentCard: (geo_country) =>
              Api.createPaymentIntentCard(id, product.id, geo_country),
            createPaymentIntentPaypal: () =>
              Api.createPaymentIntentPaypal(id, product.id),
          }}
        />

        <div className={styles.plan}>
          <Typography
            className={styles.plan__title}
            variant={Typographies.TITLE_MEDIUM}
            component="h3"
          >
            {getProductName(product.id, product.name, t)}
          </Typography>

          <div className={styles.plan__price}>
            <Typography
              className={styles.plan__price_old}
              variant={Typographies.TITLE_MEDIUM}
              component="h3"
            >
              ${ANNUAL_PLAN_OLD_PRICE.toFixed(2)}
            </Typography>

            <Typography
              className={styles.plan__price_total}
              variant={Typographies.TITLE_MEDIUM}
              component="h3"
            >
              {t('total', 'Total')}: ${(product.price / 100).toFixed(2)}/
              {t(`${product.regularity}`, product.regularity).toLowerCase()}
            </Typography>
          </div>
        </div>

        <div className={styles.guarantee}>
          <img
            src={SecuredSystemsV2Img}
            alt=""
            className={styles.guarantee__img}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
