import { REACTION } from '~/modules/reactions/types';

export type FactCheckerType = {
  id: string;
  title: string;
};

export enum FactStatus {
  APPLY = 'apply',
  DISMISS = 'dismiss',
  NONE = 'none',
}

export type FactType = {
  id: string;
  original: string;
  status: FactStatus;
  topic: string;
  issue: string;
  corrected: string;
  type: 'wrong' | 'inexact';
  created_at: string;
  updated_at: string;
};

export type FactCheckerExecutionType = {
  id: string;
  reaction: REACTION;
  updated_text: string;
  is_copied: boolean;
  fact_checks: FactType[];
  is_in_progress?: boolean;
  created_at: string;
  updated_at: string;
  demo_mode?: boolean;
};
