import React, { FC, ReactNode, useCallback, useState } from 'react';

import { Icon } from '@iconify/react';
import { Popover, styled } from '@mui/material';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import FallbackLetterAvatar from '~/components/atoms/FallbackLetterAvatar';
import Logo from '~/components/atoms/Logo';
import ButtonSuggestion from '~/components/layouts/MainLayout/components/ButtonSuggestion';
import SidebarDesktop from '~/components/layouts/MainLayout/Sidebar/desktop';
import InnerNavigation from '~/components/molecules/InnerNavigation';
import { AvatarType } from '~/components/molecules/LogoMessageWrapper';
import SupportModal from '~/components/molecules/SupportModal';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { settingsSidebar, SideBarItemType } from '~/core/sidebar';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { checkIsSubUnlimited } from '~/utils/subscription';

import styles from './styles.module.scss';

const StyledPopover = styled(Popover)(() => ({
  '.MuiPopover-paper': {
    margin: 0,
  },
}));

type Props = {
  sidebarGroups: SideBarItemType[][];
  children?: React.ReactNode;
};

const DesktopLayout: FC<Props> = ({ children, sidebarGroups }) => {
  const { signOut } = useActions();
  const { handleOpenModal } = useModalContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const profile = useAppSelector((state) => state.profile);

  const { t } = useTranslation('general');

  const handleClickHelp = (): void => {
    analytics.trackEvent('navigation - open help section');

    handleOpenModal({
      onClose: () => analytics.trackEvent('navigation - close support modal'),
      maxWidth: '460px',
      component: ({ onClose }) => <SupportModal onClose={onClose} />,
    });
  };

  const getSettingsGroups = useCallback(() => {
    return [
      ...Object.values({
        ...settingsSidebar.getItems(),
        a: [
          ...settingsSidebar.getItems()['a'],
          {
            path: '#',
            Icon: (): ReactNode => (
              <Icon icon="material-symbols:help-outline" />
            ),
            getTitle: (t: TFunction): string => t('help', 'Help'),
            onClick: (): void => {
              handleClickHelp();
            },
          },
        ],
      }),
      [
        {
          path: '#',
          Icon: (): ReactNode => <Icon icon="material-symbols:logout" />,
          getTitle: (t: TFunction): string => t('logout', 'Logout'),
          onClick: (): void => {
            signOut();
          },
        },
      ],
    ];
  }, [settingsSidebar]);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.layout}>
      <div className={styles.layout__sidebar}>
        <SidebarDesktop items={sidebarGroups} />
      </div>

      <div className={styles.layout__main}>
        <div className={styles.header}>
          <div className={styles.header__logo_wrapper}>
            <div className={styles.header__logo}>
              <Logo />
            </div>

            {checkIsSubUnlimited(
              profile.is_stripe_user,
              profile.user_subscription.is_trial,
              profile.user_product.regularity,
            ) && (
              <div className={styles.header__unlimited_label}>
                {t('unlimited', 'Unlimited')}
              </div>
            )}
          </div>

          <div className={styles.header__user}>
            <div className={styles.buttons_panel}>
              <ButtonSuggestion />
            </div>

            <div
              onClick={handleClick}
              className={clsx({
                [styles.contributor]: Boolean(
                  profile.extra_fields.is_contributor,
                ),
              })}
            >
              <FallbackLetterAvatar
                logo_src={profile.avatar}
                variant={AvatarType.USER}
                className={styles.header__avatar}
              >
                <span>{profile.name.charAt(0)}</span>
              </FallbackLetterAvatar>
            </div>
          </div>
        </div>

        <div className={styles.content}>{children}</div>
      </div>

      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={styles.header__nav_list}>
          <InnerNavigation groups={getSettingsGroups()} />
        </div>
      </StyledPopover>
    </div>
  );
};

export default DesktopLayout;
