import {
  GrowthBook as NativeGrowthBook,
  TrackingCallback,
} from '@growthbook/growthbook';

import { AB_TEST_FEATURES, AB_TESTS_KEYS } from '~/services/abTests/features';

class GrowthBook {
  instance: null | NativeGrowthBook<Record<string, any>> = null;

  setUp(
    clientKey: string,
    trackingCallbacks: TrackingCallback[],
    attributes?: Record<string, string>,
  ): void {
    if (clientKey) {
      this.instance = new NativeGrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey,
        enableDevMode: true,
        attributes,
        trackingCallback: (experiment, result): void => {
          trackingCallbacks.forEach(
            (trackingCallback: TrackingCallback): void => {
              trackingCallback(experiment, result);
            },
          );
        },
      });
    }
  }

  async init(attributes: Record<string, any>): Promise<void> {
    if (this.instance) {
      await this.instance.setAttributes(attributes);
      await this.instance.init();
    }
  }

  getAbTestValue(key: AB_TESTS_KEYS): string {
    const defaultValue = AB_TEST_FEATURES[key].defaultVariant;

    if (this.instance) {
      return this.instance.getFeatureValue(key, defaultValue);
    }

    return defaultValue;
  }

  isTestGroup(key: AB_TESTS_KEYS): boolean {
    const defaultValue = AB_TEST_FEATURES[key].defaultVariant;

    if (this.instance) {
      const abTestValue = this.instance.getFeatureValue(key, defaultValue);
      return abTestValue !== AB_TEST_FEATURES[key].variants.control;
    }

    return false;
  }

  compareAbTests(items: { key: AB_TESTS_KEYS; value: string }[]): boolean {
    return items.every(({ key, value }) => this.getAbTestValue(key) === value);
  }

  startAbTests(): void {
    if (this.instance) {
      Object.keys(AB_TEST_FEATURES).forEach((key) => {
        const abTest = AB_TEST_FEATURES[key as AB_TESTS_KEYS];

        if (!abTest.isManualStart) {
          this.instance?.getFeatureValue(abTest.key, abTest.defaultVariant);
        }
      });
    }
  }
}

const growthBook = new GrowthBook();

export { growthBook };
