import { TFunction } from 'i18next';

import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import analytics from '~/services/analytics';

export const SidebarKey = 'chat';
export const ChatByIdPath = '/chat/:chatId';
export const getChatTitle = (t: TFunction): string =>
  t('sidebar:ai_chat', 'AI Chat');

export const ChatToolId = 'b1fe3e52-7e3f-4789-874a-60805945073e';

export const NEED_SHOW_ONBOARDING_SEQUENCE =
  'is_need_to_show_onboarding_sequence';

export const getChatHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.CHAT_ONBOARDING_STEP_1
  | HIGHLIGHT.CHAT_ONBOARDING_STEP_2
  | HIGHLIGHT.CHAT_ONBOARDING_STEP_3
  | HIGHLIGHT.CHAT_ONBOARDING_STEP_4,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.CHAT_ONBOARDING_STEP_1]: {
      title: t('chat_onboarding_step1.title', {
        ns: 'highlight',
        defaultValue: 'AI Assistant role',
      }),
      text: t('chat_onboarding_step1.text', {
        ns: 'highlight',
        defaultValue:
          'Pick personality and have a conversation tailored to your specific requests.',
      }),
      buttonText: t('continue', {
        ns: 'general',
        defaultValue: 'Continue',
      }),
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [HIGHLIGHT.CHAT_ONBOARDING_STEP_1]: 1 },
        });
        analytics.trackEvent('onboarding - do. onb highlight 1');
      },
    },
    // CURRENTLY ISN'T USED
    [HIGHLIGHT.CHAT_ONBOARDING_STEP_2]: {
      title: t('chat_onboarding_step2.title', {
        ns: 'highlight',
        defaultValue: 'Google Search',
      }),
      text: t('chat_onboarding_step2.text', {
        ns: 'highlight',
        defaultValue:
          'Let us google for you. Get up-to-date information from multiple sources within seconds processed by our AI.',
      }),
      buttonText: t('continue', {
        ns: 'general',
        defaultValue: 'Continue',
      }),
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [HIGHLIGHT.CHAT_ONBOARDING_STEP_2]: 1 },
        });
        analytics.trackEvent('onboarding - do. onb highlight 2');
      },
    },
    [HIGHLIGHT.CHAT_ONBOARDING_STEP_3]: {
      title: t('chat_onboarding_step3.title', {
        ns: 'highlight',
        defaultValue: 'Improve your input',
      }),
      text: t('chat_onboarding_step3.text', {
        ns: 'highlight',
        defaultValue:
          'Enhance your request in 1 click like a pro. Give us the short point of what you want and Improver will do the rest.',
      }),
      buttonText: t('continue', {
        ns: 'general',
        defaultValue: 'Continue',
      }),
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [HIGHLIGHT.CHAT_ONBOARDING_STEP_3]: 1 },
        });
        analytics.trackEvent('onboarding - do. onb highlight 3');
      },
    },
    [HIGHLIGHT.CHAT_ONBOARDING_STEP_4]: {
      title: t('chat_onboarding_step4.title', {
        ns: 'highlight',
        defaultValue: 'Work with websites and files',
      }),
      text: t('chat_onboarding_step4.text', {
        ns: 'highlight',
        defaultValue:
          'Automatically scan text from external website or doc. Chat Assistant could summarize it or search through it.',
      }),
      buttonText: t('continue', {
        ns: 'general',
        defaultValue: 'Continue',
      }),
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: {
            [HIGHLIGHT.CHAT_ONBOARDING_STEP_4]: 1,
            [NEED_SHOW_ONBOARDING_SEQUENCE]: false,
          },
        });
        analytics.trackEvent('onboarding - do. onb highlight 4');
        analytics.trackEvent('onboarding - do. discovery onb finished');
      },
    },
  };
};
