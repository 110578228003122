export const SUPPORT_EMAIL = 'support@justdone.ai';
export const SITE_BASE_URL = process.env.SITE_BASE_URL || 'https://justdone.ai';

export const SITEMAP_URL = `${SITE_BASE_URL}/sitemap`;
export const SITE_PRIVACY_POLICY_URL = `${SITE_BASE_URL}/legal/privacy-policy`;
export const SITE_TERMS_OF_USE_URL = `${SITE_BASE_URL}/legal/terms-of-use`;

export const MEGABYTE = 1000000;

export const USER_ID_KEY = 'app-justdone-id-key';
