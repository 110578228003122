import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  platform: 'Mac' | 'Windows';
  bcgImg: {
    src: string;
    preview: string;
  };
  stepsImg: {
    src: string;
    preview: string;
  };
  steps: string[];
  onSubmit: () => void;
};

const DesktopLayout: FC<Props> = ({
  bcgImg,
  platform,
  stepsImg,
  steps,
  onSubmit,
}) => {
  const { t } = useTranslation('modal');

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={bcgImg.src} preview={bcgImg.preview} />
      </div>

      <div className={styles.content}>
        <Typography
          variant={Typographies.TITLE_LARGE}
          className={styles.content__title}
          component="h4"
        >
          <Trans i18nKey="pwa_v5.title" t={t}>
            Install JustDone App For {{ platform }}
          </Trans>
        </Typography>

        <div className={styles.content__image}>
          <Image src={stepsImg.src} preview={stepsImg.preview} />
        </div>

        <div className={styles.content__steps}>
          {steps.map((step, idx) => {
            return (
              <Typography
                key={step}
                variant={Typographies.BODY_MEDIUM}
                className={styles.content__steps_item}
                component="div"
              >
                <div>{idx + 1}.</div>
                <div>{step}</div>
              </Typography>
            );
          })}
        </div>

        <Button
          className={styles.content__button}
          color="primary"
          variant="contained"
          fullWidth
          onClick={onSubmit}
        >
          <span>{t('general:done', 'Done')}!</span>
        </Button>
      </div>
    </div>
  );
};

export default DesktopLayout;
