import { TFunction } from 'i18next';

import { HIGHLIGHT, HighLightConfigType } from '~/modules/highlight/constants';
import { AiDetectorExecutionType } from '~/modules/tools/views/variant2/AiDetectorV2/types';
import analytics from '~/services/analytics';

export const AiDetectorTitle = 'AI Content Detector';

export enum DETECTION_STAGE {
  FULLY_HUMAN = 'fully_human',
  MOSTLY_HUMAN = 'mostly_human',
  MEDIUM = 'medium',
  MOSTLY_AI = 'mostly_ai',
  FULLY_AI = 'fully_ai',
}

export const DETECTION_EDGE: Record<DETECTION_STAGE, number> = {
  [DETECTION_STAGE.FULLY_AI]: 100,
  [DETECTION_STAGE.MOSTLY_AI]: 61,
  [DETECTION_STAGE.MEDIUM]: 31,
  [DETECTION_STAGE.MOSTLY_HUMAN]: 1,
  [DETECTION_STAGE.FULLY_HUMAN]: 0,
};

export const EXAMPLE_RESPONSE: AiDetectorExecutionType = {
  id: 'example_response',
  summary: 'The Gold Standard as a significant historical monetary system.',
  report_url: '',
  fake_percentage: 97,
  detected_sentences: [
    'The Gold Standard represents one of the most significant monetary systems in history, embodying a unique relationship between currency and precious metals.',
    "Under this system, the value of a country's currency is directly linked to a specific amount of gold, establishing a fixed exchange rate that creates stability in international trade.",
    'This framework not only facilitated cross-border transactions but also provided a sense of security for investors and consumers alike, as the intrinsic value of gold served as a reliable measure of wealth.',
    'Throughout history, the Gold Standard has been a point of contention, celebrated for its ability to promote economic stability while criticized for its inherent limitations.',
    'Its adoption marked a pivotal shift in global finance, influencing the economic policies of nations and reshaping the landscape of international commerce.',
    'As countries embraced this standard, they navigated a complex interplay of economic forces, often leading to profound implications for their financial systems.',
    'In this exploration of the Gold Standard, we will delve into its historical origins, examine its implementation across key nations, and analyze the economic repercussions it had on global trade.',
    "By understanding the Gold Standard's legacy, we can better appreciate its enduring significance in the context of modern monetary policy and economic theory.",
    'As we move forward, it is essential to recognize both its strengths and weaknesses, as these factors continue to inform discussions about currency systems today.',
  ],
  detected_special_symbols: [],
  detected_non_latin_languages: [],
  request_message:
    "I. Introduction to the Gold Standard\nThe Gold Standard represents one of the most significant monetary systems in history, embodying a unique relationship between currency and precious metals. Under this system, the value of a country's currency is directly linked to a specific amount of gold, establishing a fixed exchange rate that creates stability in international trade. This framework not only facilitated cross-border transactions but also provided a sense of security for investors and consumers alike, as the intrinsic value of gold served as a reliable measure of wealth.\n\nThroughout history, the Gold Standard has been a point of contention, celebrated for its ability to promote economic stability while criticized for its inherent limitations. Its adoption marked a pivotal shift in global finance, influencing the economic policies of nations and reshaping the landscape of international commerce. As countries embraced this standard, they navigated a complex interplay of economic forces, often leading to profound implications for their financial systems.\n\nIn this exploration of the Gold Standard, we will delve into its historical origins, examine its implementation across key nations, and analyze the economic repercussions it had on global trade. By understanding the Gold Standard's legacy, we can better appreciate its enduring significance in the context of modern monetary policy and economic theory. As we move forward, it is essential to recognize both its strengths and weaknesses, as these factors continue to inform discussions about currency systems today.",
  reaction: 0,
  created_at: '2025-02-18T12:36:49.028280057Z',
  updated_at: '2025-02-18T12:36:49.028280199Z',
  demo_mode: true,
};

export const IsShowedDetectorTutorial = 'is_showed_detector_tutorial';

export const getAiDetectorHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.AI_DETECTOR_TEXT_INPUT
  | HIGHLIGHT.AI_DETECTOR_NAVIGATOR
  | HIGHLIGHT.AI_DETECTOR_HUMANIZE_BUTTON
  | HIGHLIGHT.AI_DETECTOR_FACT_CHECK_BUTTON
  | HIGHLIGHT.AI_DETECTOR_DETAILED_REPORT_BUTTON
  | HIGHLIGHT.AI_DETECTOR_TIPS_BUTTON,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.AI_DETECTOR_TEXT_INPUT]: {
      title: t('highlight:ai_detector.text_input.title', 'AI Text Highlights'),
      text: t(
        'highlight:ai_detector.text_input.text',
        'Selected AI-generated sentence is highlighted in yellow, the rest — in blue',
      ),
      placement: 'right-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiDetectorTitle,
          tip_number: 2,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiDetectorTitle,
          tip_number: 2,
        });
      },
    },
    [HIGHLIGHT.AI_DETECTOR_NAVIGATOR]: {
      title: t('highlight:ai_detector.navigator.title', 'Navigate The Text'),
      text: t(
        'highlight:ai_detector.navigator.text',
        'These buttons allow you to highlight the next or previous sentence that is probably AI-generated. When the text is partially flagged as AI-generated, manually editing highlighted parts can be highly beneficial.',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiDetectorTitle,
          tip_number: 3,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiDetectorTitle,
          tip_number: 3,
        });
      },
    },
    [HIGHLIGHT.AI_DETECTOR_HUMANIZE_BUTTON]: {
      title: t('highlight:ai_detector.humanize_button.title', 'Humanize Text'),
      text: t(
        'highlight:ai_detector.humanize_button.text',
        'Use this feature to decrease the probability of the text being flagged by AI detection software',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiDetectorTitle,
          tip_number: 4,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiDetectorTitle,
          tip_number: 4,
        });
      },
    },
    [HIGHLIGHT.AI_DETECTOR_FACT_CHECK_BUTTON]: {
      title: t('highlight:ai_detector.fact_check_button.title', 'Fact Check'),
      text: t(
        'highlight:ai_detector.fact_check_button.text',
        'Get a detailed fact-check report of the text to fix possible issues in this regard',
      ),
      placement: 'bottom-end',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiDetectorTitle,
          tip_number: 5,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiDetectorTitle,
          tip_number: 5,
        });
      },
    },
    [HIGHLIGHT.AI_DETECTOR_DETAILED_REPORT_BUTTON]: {
      title: t(
        'highlight:ai_detector.detailed_report_button.title',
        'Detailed Report',
      ),
      text: t(
        'highlight:ai_detector.detailed_report_button.text',
        'Get a document with complete information about the text regarding AI content inside',
      ),
      placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiDetectorTitle,
          tip_number: 6,
        });
      },
      onSubmitCallback: (): void => {
        analytics.trackEvent('tutorial - click next', {
          tool: AiDetectorTitle,
          tip_number: 6,
        });
      },
    },
    [HIGHLIGHT.AI_DETECTOR_TIPS_BUTTON]: {
      title: t(
        'highlight:ai_detector.tips_button.title',
        'Tips To Lower AI Score',
      ),
      text: t(
        'highlight:ai_detector.tips_button.text',
        'Try using our proven tips to eliminate AI traces when humanized text still gets flagged as AI-generated',
      ),
      placement: 'bottom-end',
      buttonText: t('general:finish', 'Finish'),
      onShowCallBack: (): void => {
        analytics.trackEvent('tutorial - show tip', {
          tool: AiDetectorTitle,
          tip_number: 7,
        });
      },
      onSubmitCallback: (updateUserExtraFields): void => {
        updateUserExtraFields({
          highlights: { [IsShowedDetectorTutorial]: true },
        });
        analytics.trackEvent('tutorial - finished', {
          tool: AiDetectorTitle,
        });
      },
    },
  };
};
