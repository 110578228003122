import React, { ReactElement } from 'react';

import { Icon } from '@iconify/react';
import { TFunction } from 'i18next';
import { cloneDeep } from 'lodash';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { FORM_FIELDS, FormDataType, UiFieldType } from '~/modules/tools/types';

export const addTrimValidationToScheme = (
  // TODO in future backend should modify all needed json_schemes in DB (pattern: '\\S')
  validationSchemaToModify: Record<string, any>,
  uiSchema: Record<string, UiFieldType>,
): Record<string, any> => {
  if (
    validationSchemaToModify.properties &&
    validationSchemaToModify.required.length
  ) {
    const textFields = Object.entries(uiSchema)
      .filter(
        ([key, fieldConfig]) =>
          [
            FORM_FIELDS.TEXT,
            FORM_FIELDS.TEXTAREA,
            FORM_FIELDS.TEXTAREA_UPLOAD,
          ].includes(fieldConfig.type) &&
          validationSchemaToModify.required.includes(key),
      )
      .map(([fieldName]) => fieldName);

    if (textFields.length) {
      const clonedValidationSchema = cloneDeep(validationSchemaToModify);
      textFields.forEach((fieldName) => {
        if (clonedValidationSchema.properties[fieldName]) {
          clonedValidationSchema.properties[fieldName].pattern = '\\S';
        }
      });
      return clonedValidationSchema;
    }
  }

  return validationSchemaToModify;
};

export const wrapWithErrorMarkup = (
  text: string | ReactElement,
): JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      <Icon icon="material-symbols:error-outline" width="18" />
      <span style={{ fontWeight: 400, fontSize: '12px', lineHeight: '1' }}>
        {text}
      </span>
    </div>
  );
};

export const updateErrorMessages = (
  errors: FieldErrors<yup.InferType<any>>,
  validationSchema: Record<string, any>,
  currentValues: FormDataType,
  t: TFunction,
): FieldErrors<yup.InferType<any>> => {
  // @ts-ignore
  return Object.fromEntries(
    // @ts-ignore
    Object.entries(errors).map(([key, value]: [string, { type: string }]) => {
      if (['required', 'matches'].includes(value.type)) {
        return [
          key,
          {
            ...value,
            message: wrapWithErrorMarkup(
              t('field_required', {
                ns: 'error',
                defaultValue: 'Required field',
              }),
            ),
          },
        ];
      }

      if (['max', 'min'].includes(value.type)) {
        const flatProperties = {
          ...validationSchema.properties,
          ...validationSchema?.allOf?.reduce(
            (acc, curr) => ({ ...acc, ...curr.then.properties }),
            {},
          ),
        };

        const isNumberField = flatProperties[key]?.type === 'number';

        if (isNumberField) {
          const minValue = flatProperties[key]?.minimum || 0;
          const maxValue = flatProperties[key]?.maximum || 0;

          return [
            key,
            {
              ...value,
              message: wrapWithErrorMarkup(
                value.type === 'max'
                  ? t('number_limit_exceeded', {
                      ns: 'error',
                      max_value: maxValue,
                      defaultValue: 'Must be at most {{max_value}}',
                    })
                  : t('number_limit_insufficient', {
                      ns: 'error',
                      min_length: minValue,
                      defaultValue: 'Must be at least {{min_length}}',
                    }),
              ),
            },
          ];
        }

        const minLength = flatProperties[key]?.minLength || 0;
        const maxLength = flatProperties[key]?.maxLength || 0;
        const currentValueLength = (currentValues[key] as string).length;

        const currentMinRange =
          typeof currentValues[key] === 'string'
            ? `(${currentValueLength}/${minLength})`
            : '';

        const currentMaxRange =
          typeof currentValues[key] === 'string'
            ? `(${currentValueLength}/${maxLength})`
            : '';

        return [
          key,
          {
            ...value,
            message: wrapWithErrorMarkup(
              value.type === 'max'
                ? t('characters_limit_exceeded', {
                    ns: 'error',
                    max_length: maxLength,
                    current_max_range: currentMaxRange,
                    defaultValue:
                      'Must be at most {{max_length}} characters {{-current_max_range}}',
                  })
                : t('characters_limit_insufficient', {
                    ns: 'error',
                    min_length: minLength,
                    current_min_range: currentMinRange,
                    defaultValue:
                      'Must be at least {{min_length}} characters {{-current_min_range}}',
                  }),
            ),
          },
        ];
      }

      return [key, value];
    }),
  );
};
